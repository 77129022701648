import React, { useEffect, useState } from "react";
import { Link } from "components";
import styles from "./Documents.module.scss";
import { axiosStatic } from "plugins/axios";
import download from "assets/img/download.svg";
import eye from "assets/img/eye.svg";

const Documents = () => {
  const [documents, setDocuments] = useState([]);

  const getAllDocs = async () => {
    let result = await axiosStatic.getAllDocs();
    setDocuments(result || []);
  };

  const downloadDocs = async (name) => {
    let result = await axiosStatic.getDocument(name);
    const blob = new Blob([result], {
      type: "application/pdf",
    });
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", name);
    a.click();
  };

  useEffect(() => {
    getAllDocs();
  }, []);
  return (
    <div className={styles.documents}>
      <h1>Документы</h1>
      {documents?.map((document, index) => (
        <div className={styles.document} key={index}>
          <p>{document.slice(0, -4)}</p>
          <div className={styles.buttons}>
            <Link
              path={
                process.env.REACT_APP_BASE_URL + "/api/info/file/" + document
              }
              color="#55C83D"
              target="_blank"
            >
              <div>
                <h2>Читать</h2>
                <img src={eye} alt="show" />
              </div>
            </Link>

            <div onClick={() => downloadDocs(document)}>
              <h2>Скачать</h2>
              <img src={download} alt="download" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export { Documents };
