import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./Deliveries.module.scss";
import { axiosUserProfile, axiosProduct } from "plugins/axios";
import { Button, NewProduct, SliderProducts } from "components";
import EmptyProduct from "assets/img/empty_product.png";
import notification_order from "assets/img/notification_order.svg";

const Notifications = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [notifications, setNotifications] = useState([]);
  const [history, setHistory] = useState([]);

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const getNotificatoins = async () => {
    let result = await axiosUserProfile.getNotifications();
    setNotifications(result || []);
  };

  const changeSeenNotification = async (order_number) => {
    await axiosUserProfile.setNotificationSeen(order_number);
    getNotificatoins();
  };

  const getMyHistory = async () => {
    let products = JSON.parse(localStorage.getItem("history")) || [];
    let res = await axiosProduct.getMyHistoryProducts({ products });
    setHistory(res.history || []);
  };

  useEffect(() => {
    getNotificatoins();
    getMyHistory();
  }, []);

  return (
    <div className={styles.track_orders}>
      {!!notifications?.length && (
        <div className={styles.notifications}>
          <div className={styles.notifications_header}>
            <img src={notification_order} alt="Уведомление" />
            Уведомление
          </div>
          {notifications.map((item) => (
            <div key={item.id} className={styles.notifications_item}>
              <div className={styles.notifications_track_body}>
                <img src={item.pic_url || EmptyProduct} alt="Картинка" />
                <div>
                  <p>Статус вашего заказа изменен!</p>
                  <p className={styles.status}>{item.status}</p>
                  <p className={styles.date}>
                    {item.change_date.slice(8, 10)}{" "}
                    {months[Number(item.change_date.slice(5, 7))]},{" "}
                    {item.change_date.slice(11, 16)}
                  </p>
                </div>
              </div>

              <div className={styles.buttons}>
                <Button
                  text="Просмотрено"
                  padding="1.2vh 1vw"
                  click={() => changeSeenNotification(item.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {!!history.length && (
        <div className={styles.history_title}>Вы смотрели</div>
      )}
      {isMobile ? (
        !!history.length && <SliderProducts items={history} />
      ) : (
        <div className={styles.history}>
          {history.map((item) => (
            <NewProduct
              key={item.id}
              item={item}
              width="auto"
              height="auto"
              imgStyle={{ width: "10vw" }}
              isDefaultStyle
              slider
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { Notifications };
