import React, { useEffect } from "react";
import styles from "./Statics.module.scss";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.about__cont}>
      <div className={styles.about__header}>
        <span className={styles.about__header__title}>“Экосистема Шалфей”</span>
        <span className={styles.about__header__subtitle}>
          уникальное онлайн-пространство
        </span>
      </div>
      <div className={styles.about__body}>
        <div className={styles.about__block}>
          <div className={styles.about__block__text}>
            <p className={styles.about__block__text__title}>О нас</p>
            {ABOUT_US.map((text, index) => (
              <p key={index} className={styles.about__block__text__desc}>
                {text}
              </p>
            ))}
          </div>
          <div
            className={`${styles.about__block__image} ${styles.about__block__image_1}`}
          />
        </div>
        <div className={styles.about__second_block}>
          <div
            className={`${styles.about__block__image} ${styles.about__block__image_2}`}
          />
          <div className={styles.about__block__text}>
            <p className={styles.about__block__text__title}>Наша цель</p>
            {OUR_GOAL.map((text, index) => (
              <p key={index} className={styles.about__block__text__desc}>
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { About };

const ABOUT_US = [
  'Наш маркетплейс "Экосистема Шалфей" представляет собой уникальное онлайн-пространство, объединяющее различные направления народной медицины и превентивной заботы о здоровье.',
  "Работая на рынке России, мы собрали на одной площадке богатый ассортимент товаров из русской народной, аюрведической, китайской, тибетской и вьетнамской медицин, а также создали раздел превентивной (профилактической) медицины.",
  "Уникальность нашего подхода заключается не только в разнообразии товаров, но и в инновационной системе классификации, основанной на показаниях к действующему веществу препарата из народной медицины. Это позволяет нашим клиентам быстро и удобно находить необходимые продукты, строго в соответствии с их индивидуальными потребностями.",
  "Мы гордимся тем, что за качество классификации и мониторинга ассортимента отвечают опытные модераторы с медицинским образованием, обеспечивая нашим клиентам высокий уровень сервиса и безопасности.",
];
const OUR_GOAL = [
  "Наша цель - не только предоставлять доступ к продуктам, но и обеспечивать информирование и образование наших пользователей. На сайте Экосистемы Шалфей регулярно появляются статьи и публикации, созданные врачами и экспертами в области народной медицины, а также научно-познавательный контент для повышения медицинской грамотности и осознанного подхода к здоровью.",
  "Мы стремимся к постоянному улучшению нашего сервиса и планируем внедрение новых инструментов, таких как анкета для помощи в подборе препаратов, чтобы обеспечить нашим клиентам еще более персонализированный опыт.",
  "Мы сотрудничаем с самыми надежными партнерами по доставке, такими как DPD, Boxberry, Почта России, FivePost, обеспечивая нашим клиентам быструю и удобную доставку в любую точку страны.",
];
