import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import logo from "assets/img/logo_white.png";
import vk from "assets/img/vk.svg";
import tg from "assets/img/tg.svg";
import youtube from "assets/img/youtube.svg";
import { Link, ModalСontacts } from "components";

import * as links from "constants/url";
import { SOCIAL_NETWORKS, EMAIL } from "constants/socialNetwork";
import { axiosStatic } from "plugins/axios";
import { DOWNLOAD_REQUISITES } from "plugins/endpoints";

const Footer = () => {
  const [socialNetwork, setSocialNetwork] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const getSocialNetworks = async () => {
      const arrSocialNetworks = [];
      for (let i = 0; i < SOCIAL_NETWORKS.length; i++) {
        let res = await axiosStatic.getSocialLink(SOCIAL_NETWORKS[i].type);
        switch (SOCIAL_NETWORKS[i].title) {
          case "Телеграм":
            arrSocialNetworks.push({
              id: i,
              text: SOCIAL_NETWORKS[i].title,
              pic: tg,
              path: res.data
                ? SOCIAL_NETWORKS[i].type === EMAIL.type
                  ? res.data.link.replace("http://", "mailto:")
                  : res.data.link
                : "/",
            });
            break;
          case "Вконтакте":
            arrSocialNetworks.push({
              id: i,
              text: SOCIAL_NETWORKS[i].title,
              pic: vk,
              path: res.data
                ? SOCIAL_NETWORKS[i].type === EMAIL.type
                  ? res.data.link.replace("http://", "mailto:")
                  : res.data.link
                : "/",
            });
            break;
          case "Youtube":
            arrSocialNetworks.push({
              id: i,
              text: SOCIAL_NETWORKS[i].title,
              pic: youtube,
              path: res.data
                ? SOCIAL_NETWORKS[i].type === EMAIL.type
                  ? res.data.link.replace("http://", "mailto:")
                  : res.data.link
                : process.env.REACT_APP_USER_URL + "/",
            });
            break;
        }
      }

      setSocialNetwork(arrSocialNetworks);
    };
    getSocialNetworks();
  }, []);
  const navItems = [
    {
      text: "Покупателям",
      subItems: [
        {
          path: links.BUYERS.url + links.HOW_TO_MAKE_ORDER.url,
          text: links.HOW_TO_MAKE_ORDER.title,
        },
      ],
    },
    {
      text: "Продавцам",
      subItems: [
        {
          get path() {
            let token = localStorage.getItem("token");
            let refresh_token = localStorage.getItem("refresh_token");

            const sellerUrl = new URL(
              process.env.REACT_APP_SELLER_URL + links.INSTRACTION.url
            );
            sellerUrl.searchParams.append("token", token);
            sellerUrl.searchParams.append("refresh_token", refresh_token);
            return sellerUrl.toString();
          },
          text: links.HOW_TO_SELL.title,
          isNewURL: true,
        },
      ],
    },
    {
      text: "Шалфей",
      subItems: [
        {
          path: links.ABOUT_US.url,
          text: links.ABOUT_US.title,
        },
        {
          path: process.env.REACT_APP_BASE_URL + DOWNLOAD_REQUISITES,
          text: links.PAYMENT_DETAILS.title,
          isNewURL: true,
        },
        {
          path: links.DOCUMENTS.url,
          text: links.DOCUMENTS.title,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.container}>
        {isOpen && <ModalСontacts onClose={() => setIsOpen(false)} />}
        <div
          style={{ display: "flex", flexDirection: "row", margin: "0 5.1vh" }}
        >
          <img
            src={logo}
            alt="Маркет плейс народной медицины"
            className={styles.logo}
          />
          <div className={styles.mobile_logotext}>
            Персональный гид в мире народной медицины
          </div>
          <div className={styles.logotext}>
            Персональный гид в мире народной медицины
            <br />
            <br />
            <Link path="mailto:info.shalfey@yandex.ru" color="#fff">
              info.shalfey@yandex.ru
            </Link>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Команда заботы
            </p>
          </div>
        </div>
        <nav className={styles.nav}>
          {navItems.map((item, i) => (
            <ul key={i} className={styles.ul}>
              <li
                key={item.text}
                className={`${styles["nav__item"]} ${styles.link}`}
              >
                {item.text}
              </li>
              {item.subItems.map((item) => (
                <li key={item.text} className={styles["nav__item"]}>
                  {item?.isNewURL ? (
                    <Link
                      onClick={() => window.open(item.path, "_blank").focus()}
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <Link path={process.env.REACT_APP_USER_URL + item.path}>
                      {item.text}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          ))}
        </nav>

        <div className={styles.mobile_data}>
          <div className={styles.mobile_links}>
            <p
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Команда заботы
            </p>
            <Link path="mailto:info.shalfey@yandex.ru" color="#fff">
              info.shalfey@yandex.ru
            </Link>
            <p>г.Санкт-Петерубрг</p>
          </div>

          <nav className={styles.mobile_nav}>
            {navItems.map((item, i) => (
              <ul key={i} className={styles.ul}>
                <li
                  key={item.text}
                  className={`${styles["nav__item"]} ${styles.link}`}
                >
                  {item.text}
                </li>
                {item.subItems.map((item) => (
                  <li key={item.text} className={styles["nav__item"]}>
                    {item?.isNewURL ? (
                      <Link
                        onClick={() => window.open(item.path, "_blank").focus()}
                      >
                        {item.text}
                      </Link>
                    ) : (
                      <Link path={process.env.REACT_APP_USER_URL + item.path}>
                        {item.text}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            ))}
          </nav>

          <ul className={styles.ul}>
            <li
              className={`${styles["nav__item"]} ${styles.link} ${styles.we_are_online}`}
            >
              Мы в сети
            </li>
            <div
              style={{ display: "flex", flexDirection: "row" }}
              className={styles.socials_network}
            >
              {socialNetwork.map((item) => (
                <li key={item.text}>
                  <Link path={item.path}>
                    <img style={{ height: "3vh" }} src={item.pic} alt="Сеть" />
                  </Link>
                </li>
              ))}
            </div>
            <p>г.Санкт-Петерубрг</p>
          </ul>
        </div>
      </div>
      <div className={styles.author}>
        <div className={styles["horizontal-line"]} />
        <p>Помните, что по вопросам касающихся здоровья</p>
        <p>необходимо проконсультироваться с врачом.</p>
        <p>© Шалфей 2023-{new Date().getFullYear()}</p>
      </div>
    </>
  );
};

export { Footer };
