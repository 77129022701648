import styles from "./ServiceInfo.module.scss";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { ModalImg, MobileModalImg } from "components";
import { toast } from "react-toastify";
import { checkError } from "plugins/checkError";
import { axiosProduct, axiosFavorite } from "plugins/axios";

import zoom from "assets/img/zoom.svg";
import up from "assets/img/up.svg";
import down from "assets/img/down.svg";
import telegram from "assets/img/telegram.svg";
import whatsup from "assets/img/whatsup.svg";

const ServiceInfo = ({ service, create_service, ...rest }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [selectedImage, setSelectedImage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const favoriteItem = JSON.parse(
    localStorage.getItem("favorite_services")
  )?.find((el) => el === service.id);
  const [inFavorite, setInFavorite] = useState(false);
  const [modalMobileOpen, setModalMobileOpen] = useState(false);

  const addOrRemoveFavorite = async () => {
    if (localStorage.getItem("token")) {
      let resp = null;
      if (inFavorite) {
        resp = await axiosFavorite.removeFromFavoriteService(service.id);
      } else {
        resp = await axiosFavorite.addToFavoriteService(service.id);
      }
      let { isError, messageError } = checkError(resp);
      if (isError) {
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        return;
      }
    } else {
      if (inFavorite) {
        let newFavorite = JSON.parse(
          localStorage.getItem("favorite_services")
        ).filter((e) => e !== service.id);
        localStorage.setItem("favorite_services", JSON.stringify(newFavorite));
      } else {
        let newFavorite = localStorage.getItem("favorite_services")
          ? [
              service.id,
              ...JSON.parse(localStorage.getItem("favorite_services")),
            ]
          : [service.id];
        localStorage.setItem("favorite_services", JSON.stringify(newFavorite));
      }
    }
    if (inFavorite) {
      setInFavorite(false);
    } else {
      setInFavorite(true);
    }
  };

  useEffect(() => {
    setInFavorite(
      localStorage.getItem("token")
        ? service.in_favorite
        : favoriteItem !== undefined
    );
  }, [favoriteItem, service]);

  const showCategory = (id) => {
    const categories = document.getElementById(id);
    const galochka = document.getElementById("galochka_" + id);
    if (categories.style.display === "block") {
      categories.style.display = "none";
      galochka && (galochka.innerHTML = `<img src=${down} alt="Вниз" />`);
    } else {
      categories.style.display = "block";
      galochka && (galochka.innerHTML = `<img src=${up} alt="Вверх" />`);
    }
  };

  const visitRemoteSite = async () => {
    await axiosProduct.watchRemoteServiceUrl(service.id);
    window.open(service?.main_site || "", "_blank");
  };

  return (
    <div style={{ ...rest }}>
      <div className={styles.content}>
        {isMobile && <h1 className={styles.name}>{service?.name}</h1>}
        <div className={styles.images}>
          <div className={styles.all_pic}>
            {service.pic_urls?.map((item, index) => (
              <div
                key={index}
                style={{
                  border: selectedImage === index ? "1px solid #20b900" : null,
                  borderRadius:
                    selectedImage === index
                      ? isMobile
                        ? "8px"
                        : "18.9952px"
                      : null,
                }}
                onClick={() => setSelectedImage(index)}
              >
                <img
                  src={
                    create_service
                      ? URL.createObjectURL(item.pic_url)
                      : item.pic_url
                  }
                  alt="Услуга"
                />
              </div>
            ))}
          </div>
          <div className={styles.main_pic}>
            {service.pic_urls
              ? service.pic_urls?.length !== 0 && (
                  <img
                    src={
                      create_service
                        ? URL.createObjectURL(
                            service?.pic_urls[selectedImage]?.pic_url
                          )
                        : service?.pic_urls[selectedImage]?.pic_url
                    }
                    className={styles.main_img}
                    alt="Услуга"
                    onClick={() => {
                      if (isMobile) setModalMobileOpen(true);
                    }}
                  />
                )
              : null}
            <img
              src={zoom}
              className={styles.zoom}
              alt="Увеличить"
              onClick={() => setModalOpen(!modalOpen)}
            />
          </div>
        </div>
        <MobileModalImg
          isOpen={modalMobileOpen}
          pic_urls={service.pic_urls}
          onClose={() => setModalMobileOpen(false)}
        />
        {modalOpen ? (
          <ModalImg
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            pic_urls={service?.pic_urls}
            main_img={selectedImage}
            create={create_service}
          />
        ) : null}

        <div className={styles.main_info}>
          {!isMobile && <h1 className={styles.name}>{service?.name}</h1>}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1vh 0",
            }}
          >
            <div className={styles.site} onClick={visitRemoteSite}>
              {service?.main_site}
            </div>
            {isMobile && (
              <div className={styles.like} onClick={addOrRemoveFavorite}>
                <div
                  title={
                    inFavorite
                      ? "Удалить из Избранного"
                      : "Добавить в Избранное"
                  }
                  className={`${styles.heart} ${
                    inFavorite ? styles.heart_active : styles.heart_not_active
                  }`}
                >
                  <svg
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_938_2" fill="white">
                      <path d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z" />
                    </mask>
                    <path
                      d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z"
                      fill="white"
                      stroke="#C1C1C1"
                      strokeWidth="1.88"
                      mask="url(#path-1-inside-1_938_2)"
                    />
                  </svg>
                </div>
                <p>В&nbsp;{inFavorite ? "избранном" : "избранное"}</p>
              </div>
            )}
          </div>

          <div
            className={styles.paragraph}
            onClick={() => showCategory("description")}
          >
            <h2 className={styles.composition}>Описание</h2>
            <b id={`galochka_description`}>
              <img src={down} alt="Вверх" />
            </b>
          </div>
          <div className={styles["horizontal-line"]} />
          <div id="description" className={styles.text}>
            {service?.description}
          </div>

          <div
            className={styles.paragraph}
            onClick={() => showCategory("composition")}
          >
            <h2 className={styles.composition}>Контакты</h2>
            <b id={`galochka_composition`}>
              <img src={down} alt="Вверх" />
            </b>
          </div>
          <div className={styles["horizontal-line"]} />
          <div
            id="composition"
            className={styles.text}
            style={{ lineHeight: "3.2vh" }}
          >
            {service?.address}
            <br />
            {service?.phone}
          </div>
          {(service.whatsapp_phone || service.telegram_phone) && (
            <div>
              <h2 className={styles.composition}>
                Запись осуществляется через
                {service.whatsapp_phone && " What’s Up "}
                {service.whatsapp_phone && service.telegram_phone && "и"}
                {service.telegram_phone && " Telegram "}
              </h2>{" "}
              <div className={styles["horizontal-line"]} />
              <div>
                {service.telegram_phone && (
                  <img
                    src={telegram}
                    alt="telegram"
                    style={{ height: "2.6vh", marginRight: "1vh" }}
                  />
                )}
                {service.whatsapp_phone && (
                  <img
                    src={whatsup}
                    alt="whatsup"
                    style={{ height: "2.6vh", marginRight: "1vh" }}
                  />
                )}
              </div>
            </div>
          )}
          <div style={{ marginTop: "0.5vh" }}>
            {service?.types_of_service?.map((type, index) => (
              <h1 key={index} className={styles.types}>
                {create_service ? type : type?.name}
              </h1>
            ))}
          </div>
        </div>
        {!isMobile && !create_service && (
          <div className={styles.like} onClick={addOrRemoveFavorite}>
            <div
              title={
                inFavorite ? "Удалить из Избранного" : "Добавить в Избранное"
              }
              className={`${styles.heart} ${
                inFavorite ? styles.heart_active : styles.heart_not_active
              }`}
            >
              <svg
                viewBox="0 0 26 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="path-1-inside-1_938_2" fill="white">
                  <path d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z" />
                </mask>
                <path
                  d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z"
                  fill="white"
                  stroke="#C1C1C1"
                  strokeWidth="1.88"
                  mask="url(#path-1-inside-1_938_2)"
                />
              </svg>
            </div>
            <p>В&nbsp;{inFavorite ? "избранном" : "избранное"}</p>
          </div>
        )}
      </div>
      <div>
        {service.services?.map((item, index) => (
          <div className={styles.block_services} key={index}>
            <div className={styles.service}>
              <h1>{item?.name}</h1>
              <h2>{item?.description}</h2>

              <h3 onClick={() => showCategory(index)}>Подробнее</h3>
              <div id={index} style={{ display: "none" }}>
                <div className={styles["horizontal-line"]} />

                <h4>{item?.description}</h4>
              </div>
            </div>
            <div className={styles.price}>
              <p>{item?.price}</p>
              <div onClick={visitRemoteSite}>
                <h1>Записаться</h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ServiceInfo };
