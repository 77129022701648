import styles from "./Main.module.scss";
import {
  MainMenu,
  Recommendations,
  SliderProducts,
  Button,
  Input,
} from "components";
import React, { useEffect, useState } from "react";
import { axiosHome } from "plugins/axios";
import questionnaire from "assets/img/questionnaire.png";

import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { QUESTIONNAIRE } from "constants/url";

const Main = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [hits, setHits] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [news, setNews] = useState([]);

  const updateProductName = async () =>
    navigate(`/catalogue/search?name=${searchText}`);

  const getHits = async () => {
    let result = await axiosHome.getHits();
    setHits(result.purchased || []);
  };

  const getPromotions = async () => {
    let result = await axiosHome.getPromotions();
    setPromotions(result.promoted || []);
  };

  const getNew = async () => {
    let result = await axiosHome.getNew();
    setNews(result.newest || []);
  };

  useEffect(() => {
    getHits();
    getPromotions();
    getNew();
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={styles.content}>
      <Helmet title="Выбери свой мир медицины">
        <meta name="description" content="Shalfey : Выбери свой мир медицины" />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className={styles.menu}>
        <MainMenu />
      </div>
      {isMobile ? (
        <div style={{ width: "-webkit-fill-available" }}>
          <Input
            green
            placeholder="Искать товары"
            value={searchText}
            margin="2vh 5vw"
            prepend={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                onClick={updateProductName}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.3706 8.79249C15.3706 12.4107 12.4282 15.3524 8.78802 15.3524C5.14782 15.3524 2.20545 12.4107 2.20545 8.79249C2.20545 5.17428 5.14782 2.23255 8.78802 2.23255C12.4282 2.23255 15.3706 5.17428 15.3706 8.79249ZM14.0693 15.3863C12.6218 16.541 10.7858 17.2315 8.78802 17.2315C4.11481 17.2315 0.326416 13.4532 0.326416 8.79249C0.326416 4.13177 4.11481 0.353516 8.78802 0.353516C13.4612 0.353516 17.2496 4.13177 17.2496 8.79249C17.2496 10.7848 16.5574 12.6158 15.3997 14.0594L21.6738 20.3167L20.3469 21.6471L14.0693 15.3863Z"
                  fill="#20B900"
                  fillOpacity="1"
                />
              </svg>
            }
            onChange={(ev) => setSearchText(ev.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") updateProductName();
            }}
          />
          <div className={styles.questionnaire}>
            <p>
              Индивидуальное решение,
              <br /> для вашего здоровья
            </p>
            <Button
              text="Анкета"
              width="100%"
              height="5.1vh"
              prepend={<img src={questionnaire} alt="Анкета" />}
              margin="0 auto"
              padding={isMobile ? "1vh 2vw" : "0px 50px"}
              newLight
              click={() => navigate(QUESTIONNAIRE.url)}
            />
          </div>
          <div className={styles.recommend}>
            <div className={styles.group}>
              <div className={styles.group__header}>Хиты продаж</div>
              <SliderProducts items={hits} isMoveArrow={false} />
            </div>
            <div className={styles.group}>
              <div className={styles.group__header}>Новинки</div>
              <SliderProducts items={news} isMoveArrow={false} />
            </div>
            {!!promotions.length && (
              <div className={styles.group}>
                <div className={styles.group__header}>Акции</div>
                <SliderProducts items={promotions} isMoveArrow={false} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.recommend}>
          <div className={styles.tape1}>
            <Recommendations
              title="Хиты продаж"
              products={hits}
              pathRecommendation="/catalogue/hits"
              pathItem="/product/"
            />
          </div>
          <div className={styles.tape2}>
            <Recommendations
              title="Новинки"
              products={news}
              pathRecommendation="/catalogue/new"
              pathItem="/product/"
              isDefaultStyle={false}
            />
          </div>
          <div className={styles.tape3}>
            <Recommendations
              title="Акции"
              products={promotions}
              pathRecommendation="/catalogue/promotions"
              pathItem="/product/"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export { Main };
