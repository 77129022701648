import { useState, useEffect } from "react";
import styles from "./ModalReview.module.scss";
import { Button, Textarea } from "components";
import { Rating } from "react-simple-star-rating";
import { useMediaQuery } from "react-responsive";

import star from "assets/img/new_star.svg";
import new_star_empty from "assets/img/new_star_empty.svg";
import delete_image from "assets/img/delete_image.svg";
import mobile_delete from "assets/img/mobile_delete.svg";
import add_photo from "assets/img/add_photo.png";
import EmptyProduct from "assets/img/empty_product.png";
import { axiosUserProfile } from "plugins/axios";

const ModalReview = ({ onClose = null, id, rate }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [purchase, setPurchase] = useState([]);
  const [userRate, setUserRate] = useState(rate);
  const [comment, setComment] = useState("");
  const [Images, setImages] = useState([]);

  const deletePicture = async (image) =>
    setImages(Images.filter((item) => item !== image));

  const submitData = async (event) => {
    event.preventDefault();
    await axiosUserProfile.sendMark(id, { mark: userRate, comment, Images });
    onClose();
  };

  const getPurchase = async () => {
    if (id !== 0) {
      let result = await axiosUserProfile.getPurchase(id);
      setPurchase(result);
    }
  };

  useEffect(() => {
    getPurchase();
  }, []);

  return (
    <div className={styles.form}>
      <p>Отзыв о товаре</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={styles.main_pic}>
          {purchase.pic_urls ? (
            <img
              src={purchase?.pic_urls[0]?.pic_url || EmptyProduct}
              className={styles.main_img}
              alt="Товар"
            />
          ) : null}
        </div>
        <div className={styles.main_info}>
          <h1 className={styles.name}>{purchase.name}</h1>
          {!isMobile && (
            <>
              {purchase?.type_of_medicine?.name && (
                <div className={styles.weight}>
                  <h1>Мир медицины: </h1>
                  <h2>{purchase?.type_of_medicine?.name}</h2>
                </div>
              )}
              <div className={styles.weight}>
                <h1>Вес/Объём: </h1>
                <h2>
                  {purchase.weight} {purchase.unit}
                </h2>
              </div>
              <div className={styles.weight}>
                <h1>Форма выпуска: </h1>
                <h2>{purchase.medicine_format}</h2>
              </div>
            </>
          )}
          <h1 className={styles.price}>{purchase.price} ₽</h1>
        </div>
      </div>

      <div className={styles.heading}>Ваша оценка</div>
      <Rating
        initialValue={userRate}
        transition
        titleSeparator="из"
        fillIcon={
          <img
            src={star}
            width={22}
            height={22}
            style={{ marginRight: "5px" }}
            alt="Звезда"
          />
        }
        emptyIcon={
          <img
            src={new_star_empty}
            width={22}
            height={22}
            style={{ marginRight: "5px" }}
            alt="Звезда"
          />
        }
        onClick={(rate) => setUserRate(rate)}
      />
      <div className={styles.heading}>Комментарий</div>
      <Textarea
        text={comment}
        placeholder="Ваши впечатления и пожелания"
        rows={3}
        resize="none"
        change={(event) => setComment(event.target.value)}
      />
      <div className={styles.heading}>Добавить фотографию</div>
      <div className={styles.pictures}>
        {Images.map((image, index) => (
          <div key={index}>
            {isMobile ? (
              <img
                className={styles.mobile_delete}
                src={mobile_delete}
                alt="Удалить картинку"
                onClick={() => deletePicture(image)}
              />
            ) : (
              <img
                className={styles.delete}
                src={delete_image}
                alt="Удалить картинку"
                onClick={() => deletePicture(image)}
              />
            )}
            <img
              src={URL.createObjectURL(image)}
              className={styles.img}
              alt="Картинка"
            />
          </div>
        ))}
        {Images.length < 6 && (
          <label className={styles.upload_img}>
            <img src={add_photo} alt="Картинка" />
            <input
              type="file"
              name="Фото"
              onChange={(event) => {
                if (event.target.files[0])
                  setImages([...Images, event.target.files[0]]);
              }}
            />
          </label>
        )}
      </div>
      <Button
        text="Оставить отзыв"
        padding="2vh 2vw"
        margin="2vh 0"
        click={submitData}
      />
    </div>
  );
};

export { ModalReview };
