import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./Order.module.scss";
import {
  NewProduct,
  Input,
  ModalReview,
  ModalChatSide,
  ModalWindow,
  ModalOneButton,
  Textarea,
  Tabs,
  Button,
  Checkbox,
  Link,
} from "components";

import add_photo from "assets/img/add_photo.png";
import delete_image from "assets/img/delete_image.svg";
import mobile_delete from "assets/img/mobile_delete.svg";
import search_icon from "assets/img/search.svg";
import { ToastContainer } from "react-toastify";

import { USER_CONDITIONS, PRIVACY_POLICY } from "plugins/endpoints";
import { vh, vhM, vwM } from "plugins/convertPXtoVHVW";
import { ERR_NETWORK } from "constants/status";
import { axiosUserProfile } from "plugins/axios";

const Purchases = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [purchases, setPurchases] = useState([]);
  const [count, setCount] = useState(0);
  const [sorting, setSorting] = useState("all");
  const [searchText, setSearchText] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalChatOpen, setIsModalChatOpen] = useState(false);
  const [chosenItemId, setChosenItemId] = useState(0);
  const [chosenItemChatId, setChosenItemChatId] = useState(0);
  const [rate, setRate] = useState(0);

  const [modalPurchaseReturn, setModalPurchaseReturn] = useState(false);
  const [selected, setSelected] = useState(false);
  const [purchaseItemId, setPurchaseItemId] = useState(false);
  const [comment, setComment] = useState("");
  const [Images, setImages] = useState([]);
  const [error, setError] = useState({
    comment: "",
    errorMessage: "",
  });

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(2);

  const getPurchases = async () => {
    let result = await axiosUserProfile.getPurchases(
      "?type=" + sorting + "&page=" + currentPage + "&name=" + searchText
    );

    if (result?.data?.detail === "Invalid page." || result?.status === 403) {
      setWasLastList(true);
      return;
    }
    setNextPage(!result.next ? currentPage : currentPage + 1);
    setPreviousPage(currentPage);
    setPurchases([...purchases, ...result.results.data]);
    setCount(result.results.count);
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentPage < nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const deletePicture = async (image) =>
    setImages(Images.filter((item) => item !== image));

  const createReturnProduct = async () => {
    let valid = true;
    setError({});
    if (comment === "") {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          comment: "Введите комментарий",
        };
      });
    }
    if (valid && selected) {
      let response = await axiosUserProfile.createReturnProduct({
        purchase_pk: purchaseItemId,
        comment,
        Images,
      });
      if (response.status === 201) {
        setModalPurchaseReturn(false);
      } else if (response.status === 400) {
        if (response.data.Error === "Such product return already exists") {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: "Возврат на этот товар уже оформлен",
            };
          });
        } else {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: ERR_NETWORK.message,
            };
          });
        }
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Проверьте входные данные",
        };
      });
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getPurchases();
    }
  }, [currentPage, previousPage, wasLastList, nextPage]);

  useEffect(() => {
    setCount(0);
    setPurchases([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
    setNextPage(2);
  }, [sorting]);

  return (
    <div className={styles.purchases}>
      {isModalOpen && (
        <ModalWindow
          isOpen={isModalOpen}
          width={isMobile ? "100%" : "32vw"}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalReview
            rate={rate}
            id={chosenItemId}
            onClose={() => setIsModalOpen(false)}
          />
        </ModalWindow>
      )}
      <ModalWindow
        isOpen={isModalChatOpen}
        width={isMobile ? "100%" : "28vw"}
        onClose={() => setIsModalChatOpen(false)}
      >
        <ModalChatSide id={chosenItemChatId} />
      </ModalWindow>
      <div className={styles.purchases__header}>
        Покупки&nbsp;<span>{count}</span>
      </div>
      <div className={styles.menu}>
        <Tabs
          tabs={[
            { name: "all", title: "Все" },
            { name: "received", title: "Полученные" },
            { name: "canceled", title: "Отмененные" },
          ]}
          styleTab={
            isMobile
              ? {
                  fontSize: vhM(16),
                  lineHeight: vhM(22),
                  padding: `${vhM(15)} ${vwM(13)}`,
                }
              : {}
          }
          currentTab={sorting}
          changeTab={setSorting}
        />
        <Input
          light
          placeholder="Найти в покупках"
          value={searchText}
          fontColor="#5F6B69"
          marginBottom={isMobile && "2vh"}
          prepend={
            <img
              src={search_icon}
              style={{ height: "2.5vh" }}
              alt="search"
              onClick={() => {
                setPurchases([]);
                setWasLastList(false);
                setCurrentPage(1);
                setPreviousPage(0);
                setNextPage(2);
              }}
            />
          }
          onChange={(ev) => setSearchText(ev.target.value)}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              setPurchases([]);
              setWasLastList(false);
              setCurrentPage(1);
              setPreviousPage(0);
              setNextPage(2);
            }
          }}
        />
      </div>
      <div
        className={styles.purchases__list}
        onScroll={(event) => onScroll(event)}
      >
        {!!count
          ? purchases.map((item) => (
              <NewProduct
                key={item.id}
                item={{
                  ...item,
                  id: item.product.id,
                  name: item.product.name,
                  pic_urls: item.product.pic_urls,
                }}
                width="auto"
                height="auto"
                imgStyle={{ width: "100%", height: vh(218) }}
                isDefaultStyle
                slider
                purchase
                purchaseStatus={
                  item.purchase_status === "RV" || item.return_allowed
                }
                purchaseReturn={() => {
                  setPurchaseItemId(item.id);
                  setModalPurchaseReturn(true);
                }}
                purchaseOpenModel={(rateCount) => {
                  setRate(rateCount);
                  setChosenItemId(item.id);
                  setIsModalOpen(true);
                }}
                purchaseOpenChatModel={() => {
                  setChosenItemChatId(item.id);
                  setIsModalChatOpen(true);
                }}
              />
            ))
          : "Вы ещё ничего не покупали"}
      </div>

      {isMobile ? (
        <ModalWindow
          isOpen={modalPurchaseReturn}
          width="100%"
          onClose={() => setModalPurchaseReturn(false)}
        >
          <div className={styles.mobile_return_modal}>
            <h1>Заявка на возврат</h1>
            <div className={styles.heading}>Ваш комментарий</div>
            {error.comment !== "" ? (
              <span className={styles.error}>{error.comment}</span>
            ) : null}
            <Textarea
              text={comment}
              placeholder="Текст сообщения"
              rows={3}
              width="95%"
              resize="none"
              change={(event) => setComment(event.target.value)}
            />
            <div className={styles.heading}>Добавить фотографию</div>
            <div className={styles.pictures}>
              {Images.map((image, index) => (
                <div key={index}>
                  <img
                    className={styles.mobile_delete}
                    src={mobile_delete}
                    alt="Удалить картинку"
                    onClick={() => deletePicture(image)}
                  />
                  <img
                    src={URL.createObjectURL(image)}
                    className={styles.img}
                    alt="Картинка"
                  />
                </div>
              ))}
              {Images.length < 6 && (
                <label className={styles.upload_img}>
                  <img src={add_photo} alt="Картинка" />
                  <input
                    type="file"
                    name="Фото"
                    onChange={(event) => {
                      if (event.target.files[0])
                        setImages([...Images, event.target.files[0]]);
                    }}
                  />
                </label>
              )}
            </div>
            <div style={{ display: "flex", marginBottom: "25px" }}>
              <Checkbox
                width="2vh"
                height="2vh"
                isSelected={selected}
                marginRight="1vw"
                styleBack={{ borderRadius: "0.5vh" }}
                change={(value) => setSelected(value)}
              />
              <div className={styles.link}>
                Я соглашаюсь с{" "}
                <Link
                  path={process.env.REACT_APP_BASE_URL + USER_CONDITIONS}
                  color="#55C83D"
                  target="_blank"
                >
                  Условиями использования сайта
                </Link>{" "}
                и предоставляю согласие на обработку персональных данных в
                соответствии с{" "}
                <Link
                  path={process.env.REACT_APP_BASE_URL + PRIVACY_POLICY}
                  color="#55C83D"
                  target="_blank"
                >
                  Политикой обработки персональных данных
                </Link>
              </div>
            </div>
            {error.errorMessage !== "" ? (
              <span className={styles.error}>{error.errorMessage}</span>
            ) : null}
            <Button
              text="Отправить"
              width="100%"
              padding="2vh 0"
              marginTop="4vh"
              click={createReturnProduct}
            />
          </div>
        </ModalWindow>
      ) : (
        modalPurchaseReturn && (
          <ModalOneButton
            title="Заявка на возврат товара"
            description={
              <>
                <div className={styles.heading}>Ваш комментарий</div>
                {error.comment !== "" ? (
                  <span className={styles.error}>{error.comment}</span>
                ) : null}
                <Textarea
                  text={comment}
                  placeholder="Текст сообщения"
                  rows={3}
                  width="95%"
                  resize="none"
                  change={(event) => setComment(event.target.value)}
                />
                <div className={styles.heading}>Добавить фотографию</div>
                <div className={styles.pictures}>
                  {Images.map((image, index) => (
                    <div key={index}>
                      <img
                        className={styles.delete}
                        src={delete_image}
                        alt="Удалить картинку"
                        onClick={() => deletePicture(image)}
                      />
                      <img
                        src={URL.createObjectURL(image)}
                        className={styles.img}
                        alt="Картинка"
                      />
                    </div>
                  ))}
                  {Images.length < 6 && (
                    <label className={styles.upload_img}>
                      <img src={add_photo} alt="Картинка" />
                      <input
                        type="file"
                        name="Фото"
                        onChange={(event) => {
                          if (event.target.files[0])
                            setImages([...Images, event.target.files[0]]);
                        }}
                      />
                    </label>
                  )}
                </div>
                <div style={{ display: "flex", marginBottom: "25px" }}>
                  <Checkbox
                    width="2vh"
                    height="2vh"
                    isSelected={selected}
                    marginRight="1vw"
                    styleBack={{ borderRadius: "0.5vh" }}
                    change={(value) => setSelected(value)}
                  />
                  <div className={styles.link}>
                    Я соглашаюсь с{" "}
                    <Link
                      path={process.env.REACT_APP_BASE_URL + USER_CONDITIONS}
                      color="#55C83D"
                      target="_blank"
                    >
                      Условиями использования сайта
                    </Link>{" "}
                    и предоставляю согласие на обработку персональных данных в
                    соответствии с{" "}
                    <Link
                      path={process.env.REACT_APP_BASE_URL + PRIVACY_POLICY}
                      color="#55C83D"
                      target="_blank"
                    >
                      Политикой обработки персональных данных
                    </Link>
                  </div>
                </div>
                {error.errorMessage !== "" ? (
                  <span className={styles.error}>{error.errorMessage}</span>
                ) : null}
              </>
            }
            button={{
              title: "Отправить",
              action: createReturnProduct,
            }}
            buttonStyle={{
              width: "100%",
              padding: `2vh 0`,
              fontSize: "1.8vh",
              lineHeight: "2vh",
            }}
            descriptionAlign="left"
            width="65vw"
            close={() => setModalPurchaseReturn(false)}
          />
        )
      )}
      <ToastContainer style={{ fontSize: "18px" }} />
    </div>
  );
};

export { Purchases };
