import React, { useState, useEffect } from "react";
import styles from "./Wiki.module.scss";
import { MainMenu, ArticleBlock, ModalOneButton } from "components";
import { axiosArticle } from "plugins/axios";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { useParams, useNavigate } from "react-router-dom";
import { checkError } from "plugins/checkError";
import { useOutsideClick } from "hooks";
import { MY_ARTICLES } from "constants/url";
import { useMediaQuery } from "react-responsive";

const Article = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [article, setArticle] = useState({});
  const params = useParams();
  const [error, setError] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const refEdit = useOutsideClick(() => setOpenEdit(false));
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    const getArticle = async () => {
      let res = await axiosArticle.getArticle(params.id);
      let { isError, messageError } = checkError(res);
      if (isError) {
        setError(true);
        return;
      }
      setArticle({
        ...res,
        in_favorite:
          localStorage.getItem("token") ||
          !JSON.parse(localStorage.getItem("favorite_articles"))?.includes(
            Number(res.id)
          )
            ? res.in_favorite
            : true,
      });
    };
    getArticle();
    window.scrollTo(0, 0);
  }, []);

  const favorite = async () => {
    if (localStorage.getItem("token")) {
      await axiosArticle.favoriteArticle(article.id);
    } else {
      if (article.in_favorite) {
        let newFavorite = JSON.parse(
          localStorage.getItem("favorite_articles")
        ).filter((e) => e !== article.id);
        localStorage.setItem("favorite_articles", JSON.stringify(newFavorite));
      } else {
        let newFavorite = localStorage.getItem("favorite_articles")
          ? [
              article.id,
              ...JSON.parse(localStorage.getItem("favorite_articles")),
            ]
          : [article.id];
        localStorage.setItem("favorite_articles", JSON.stringify(newFavorite));
      }
    }
    setArticle({ ...article, in_favorite: !article.in_favorite });
  };
  const removeArticle = async () => {
    let res = await axiosArticle.removeArticle(article.id);
    if (!res) {
      navigate(MY_ARTICLES.url);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {!isMobile && (
        <div className={styles.menu}>
          <MainMenu
            visibleNews={true}
            visibleServices={false}
            visibleForum={true}
            titleMedicines="Познай свой мир медицины"
          />
        </div>
      )}
      {error ? (
        <div className={styles.main_error}>Такой статьи нет</div>
      ) : (
        <>
          <div className={styles.article}>
            {isMobile && (
              <div ref={refEdit} className={styles.article__actions}>
                <div onClick={() => setOpenEdit(!openEdit)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={isMobile ? vhM(20) : vh(20)}
                    height={isMobile ? vhM(20) : vh(20)}
                    viewBox="0 0 20 20"
                    fill="none"
                    style={{ marginRight: isMobile ? vwM(4) : vw(4) }}
                  >
                    <mask id="path-1-inside-1_4750_401" fill="white">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.3102 3.57175C15.7376 2.99025 14.802 2.98303 14.2205 3.55562L5.26057 12.3783L5.26804 12.3859L5.25068 12.4042L4.24318 15.7113C4.20762 15.828 4.31644 15.9373 4.43332 15.9022L7.7129 14.9185L7.7385 14.8948L7.74768 14.9041L16.7076 6.08142C17.2891 5.50884 17.2963 4.57328 16.7238 3.99178L16.3102 3.57175Z"
                      />
                    </mask>
                    <path
                      d="M14.2205 3.55562L14.7024 4.045L14.2205 3.55562ZM16.3102 3.57175L16.7995 3.08986L16.7995 3.08986L16.3102 3.57175ZM5.26057 12.3783L4.77868 11.8889L4.2893 12.3708L4.77118 12.8602L5.26057 12.3783ZM5.26804 12.3859L5.766 12.8589L6.22349 12.3773L5.75743 11.904L5.26804 12.3859ZM5.25068 12.4042L4.75273 11.9312L4.64097 12.0488L4.59368 12.204L5.25068 12.4042ZM4.24318 15.7113L4.90018 15.9114L4.90018 15.9114L4.24318 15.7113ZM4.43332 15.9022L4.23599 15.2444H4.23599L4.43332 15.9022ZM7.7129 14.9185L7.91023 15.5764L8.06286 15.5306L8.17973 15.4223L7.7129 14.9185ZM7.7385 14.8948L8.22787 14.4129L7.76038 13.9382L7.27167 14.391L7.7385 14.8948ZM7.74768 14.9041L7.25831 15.386L7.74019 15.8754L8.22957 15.3935L7.74768 14.9041ZM16.7076 6.08142L16.2257 5.59204L16.7076 6.08142ZM16.7238 3.99178L16.2344 4.47367L16.2344 4.47367L16.7238 3.99178ZM14.7024 4.045C15.0136 3.73856 15.5143 3.74242 15.8208 4.05363L16.7995 3.08986C15.9608 2.23809 14.5904 2.22751 13.7386 3.06623L14.7024 4.045ZM5.74246 12.8677L14.7024 4.045L13.7386 3.06623L4.77868 11.8889L5.74246 12.8677ZM5.75743 11.904L5.74995 11.8964L4.77118 12.8602L4.77866 12.8678L5.75743 11.904ZM4.77009 11.9129L4.75273 11.9312L5.74863 12.8772L5.766 12.8589L4.77009 11.9129ZM4.59368 12.204L3.58618 15.5111L4.90018 15.9114L5.90768 12.6043L4.59368 12.204ZM3.58618 15.5111C3.39083 16.1524 3.9886 16.7527 4.63065 16.5601L4.23599 15.2444C4.64427 15.1219 5.0244 15.5037 4.90018 15.9114L3.58618 15.5111ZM4.63065 16.5601L7.91023 15.5764L7.51557 14.2607L4.23599 15.2444L4.63065 16.5601ZM8.17973 15.4223L8.20532 15.3986L7.27167 14.391L7.24608 14.4147L8.17973 15.4223ZM8.23706 14.4222L8.22787 14.4129L7.24912 15.3767L7.25831 15.386L8.23706 14.4222ZM16.2257 5.59204L7.2658 14.4147L8.22957 15.3935L17.1895 6.57081L16.2257 5.59204ZM16.2344 4.47367C16.5408 4.78488 16.537 5.28559 16.2257 5.59204L17.1895 6.57081C18.0413 5.73208 18.0519 4.36167 17.2131 3.5099L16.2344 4.47367ZM15.8208 4.05363L16.2344 4.47367L17.2131 3.5099L16.7995 3.08986L15.8208 4.05363Z"
                      fill="#27D003"
                      mask="url(#path-1-inside-1_4750_401)"
                    />
                    <path
                      d="M13.3247 4.45898L15.7974 6.96927"
                      stroke="#27D003"
                      strokeWidth="0.686813"
                    />
                    <path
                      d="M2.85107 16.8691L13.3247 16.8315"
                      stroke="#27D003"
                      strokeWidth="0.686813"
                      strokeLinecap="round"
                    />
                  </svg>
                  Редактировать
                </div>
                {openEdit ? (
                  <ul>
                    <li
                      onClick={() =>
                        navigate(`/wiki/my_articles/edit/${article.id}`)
                      }
                    >
                      <span>Внести изменения</span>
                    </li>
                    <li onClick={() => setIsDelete(true)}>
                      <span>Удалить статью</span>
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className={styles.article__title}>{article?.name}</div>
            <div className={styles.article__info}>
              <span>{article?.creation_date}</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? vhM(14) : vh(15)}
                  height={isMobile ? vhM(14) : vh(15)}
                  viewBox="0 0 15 15"
                  fill="none"
                  style={{ marginRight: isMobile ? vwM(3) : vw(3) }}
                >
                  <path
                    d="M11.7342 5.25347C11.8431 5.32825 11.9722 5.42842 12.1215 5.55398C12.7907 6.11636 13.3603 6.7145 13.8302 7.34838C13.9022 7.44514 13.9252 7.55175 13.8992 7.66822C13.8825 7.74275 13.832 7.83294 13.7476 7.9388C13.0483 8.81825 12.2522 9.5758 11.3594 10.2114C11.2644 10.2792 11.1507 10.353 11.0183 10.4331C10.8956 10.5074 10.7796 10.5779 10.6704 10.6446C10.5916 10.6931 10.399 10.7894 10.0925 10.9334C9.4728 11.2244 8.81757 11.3951 8.12684 11.4453C7.94974 11.4582 7.76721 11.4582 7.57924 11.4453C6.91783 11.3996 6.28155 11.2394 5.6704 10.9648C5.09564 10.7066 4.5517 10.3822 4.03859 9.99165C3.20765 9.35878 2.4842 8.63786 1.86826 7.8289C1.70682 7.61706 1.80914 7.41015 1.95391 7.23052C2.75832 6.23359 3.62741 5.43006 4.56118 4.81993C4.94797 4.56729 5.34765 4.35204 5.76022 4.17418C6.34458 3.92204 6.95635 3.77412 7.59554 3.73041C7.77112 3.71828 7.94734 3.71879 8.12419 3.73193C8.80936 3.78195 9.46231 3.95034 10.0831 4.23708C10.6538 4.50059 11.1882 4.82397 11.6864 5.20723C11.688 5.20849 11.6934 5.2114 11.7027 5.21595C11.7088 5.21923 11.7132 5.22416 11.716 5.23073C11.7198 5.24007 11.7259 5.24765 11.7342 5.25347ZM3.17607 8.12601C3.22205 8.17755 3.26588 8.22517 3.30757 8.26888C3.75121 8.73248 4.24803 9.15856 4.79803 9.54712C5.30609 9.90587 5.83108 10.1839 6.373 10.3812C6.57499 10.4547 6.78569 10.5104 6.99299 10.5586C7.53187 10.6836 8.09665 10.685 8.68733 10.5627C9.00945 10.496 9.33334 10.3921 9.65899 10.2509C10.1597 10.0338 10.6488 9.7457 11.1263 9.38644C11.8085 8.87358 12.4298 8.27898 12.9905 7.60266C12.9985 7.59281 12.9984 7.58308 12.9901 7.57348C12.2401 6.67988 11.3723 5.8833 10.3752 5.29288C9.93412 5.0319 9.47835 4.83269 9.00793 4.69525C8.35043 4.50312 7.66716 4.46939 7.0017 4.61567C6.57701 4.70889 6.16659 4.85416 5.77045 5.05148C4.73891 5.56497 3.81462 6.34071 3.02145 7.21233C2.93239 7.3101 2.835 7.44008 2.73533 7.55036C2.71158 7.57613 2.71133 7.60215 2.73457 7.62843C2.87277 7.78507 3.01993 7.95093 3.17607 8.12601Z"
                    fill="#5F6B69"
                  />
                  <path
                    d="M10.245 7.58895C10.245 8.22456 9.99254 8.83413 9.5431 9.28358C9.09365 9.73302 8.48408 9.98551 7.84847 9.98551C7.21286 9.98551 6.60329 9.73302 6.15384 9.28358C5.7044 8.83413 5.4519 8.22456 5.4519 7.58895C5.4519 6.95334 5.7044 6.34376 6.15384 5.89432C6.60329 5.44488 7.21286 5.19238 7.84847 5.19238C8.48408 5.19238 9.09365 5.44488 9.5431 5.89432C9.99254 6.34376 10.245 6.95334 10.245 7.58895ZM9.44618 7.58895C9.44618 7.16511 9.27781 6.75863 8.97811 6.45893C8.67841 6.15923 8.27193 5.99086 7.84809 5.99086C7.42425 5.99086 7.01777 6.15923 6.71807 6.45893C6.41837 6.75863 6.25 7.16511 6.25 7.58895C6.25 8.01279 6.41837 8.41927 6.71807 8.71897C7.01777 9.01867 7.42425 9.18704 7.84809 9.18704C8.27193 9.18704 8.67841 9.01867 8.97811 8.71897C9.27781 8.41927 9.44618 8.01279 9.44618 7.58895Z"
                    fill="#5F6B69"
                  />
                </svg>
                {article?.read_count || 0}
              </span>
            </div>
            <img
              src={article?.cover_url}
              width={vw(1047)}
              height={vh(520)}
              alt={article?.name}
              className={styles.article__main_img}
            />
            <div className={styles.article__description}>
              {article?.short_desc}
            </div>
            {article?.modules ? (
              article.modules.map((e, index) => (
                <ArticleBlock key={index} module={e} />
              ))
            ) : (
              <></>
            )}
            <div className={styles.article__footer}>
              <span onClick={favorite}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? vhM(20) : vh(20)}
                  height={isMobile ? vhM(17.5) : vh(19)}
                  viewBox="0 0 20 19"
                  fill="none"
                  style={{ marginRight: isMobile ? vwM(10) : vw(10) }}
                >
                  <path
                    d="M9.46942 4.18077L9.99999 5.18542L10.5305 4.18077C11.4373 2.46372 12.8451 1.35 14.6875 1.35C17.3771 1.35 19.4 3.5967 19.4 6.4478C19.4 8.29439 18.5274 9.83723 16.8759 11.5708C15.7274 12.7765 14.2589 14.0194 12.5249 15.487C11.7364 16.1543 10.8931 16.8682 9.99996 17.646C9.10748 16.8687 8.26453 16.1553 7.4763 15.4881C5.74334 14.0214 4.27487 12.7786 3.12599 11.5727C1.47373 9.83847 0.6 8.29452 0.6 6.4478C0.6 3.59626 2.62313 1.35 5.31249 1.35C7.15483 1.35 8.56259 2.46362 9.46942 4.18077Z"
                    fill={article?.in_favorite ? "#20B900" : "white"}
                    stroke="#20B900"
                    strokeWidth="1.2"
                  />
                </svg>
                Нравится
              </span>
              <span>
                <b style={{ marginRight: isMobile ? vwM(10) : vw(10) }}>
                  Автор:
                </b>
                {article?.author_name}
              </span>
            </div>
          </div>
          {article.is_mine && !isMobile ? (
            <div ref={refEdit} className={styles.article__actions}>
              <div onClick={() => setOpenEdit(!openEdit)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? vhM(20) : vh(20)}
                  height={isMobile ? vhM(20) : vh(20)}
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: isMobile ? vwM(4) : vw(4) }}
                >
                  <mask id="path-1-inside-1_4750_401" fill="white">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.3102 3.57175C15.7376 2.99025 14.802 2.98303 14.2205 3.55562L5.26057 12.3783L5.26804 12.3859L5.25068 12.4042L4.24318 15.7113C4.20762 15.828 4.31644 15.9373 4.43332 15.9022L7.7129 14.9185L7.7385 14.8948L7.74768 14.9041L16.7076 6.08142C17.2891 5.50884 17.2963 4.57328 16.7238 3.99178L16.3102 3.57175Z"
                    />
                  </mask>
                  <path
                    d="M14.2205 3.55562L14.7024 4.045L14.2205 3.55562ZM16.3102 3.57175L16.7995 3.08986L16.7995 3.08986L16.3102 3.57175ZM5.26057 12.3783L4.77868 11.8889L4.2893 12.3708L4.77118 12.8602L5.26057 12.3783ZM5.26804 12.3859L5.766 12.8589L6.22349 12.3773L5.75743 11.904L5.26804 12.3859ZM5.25068 12.4042L4.75273 11.9312L4.64097 12.0488L4.59368 12.204L5.25068 12.4042ZM4.24318 15.7113L4.90018 15.9114L4.90018 15.9114L4.24318 15.7113ZM4.43332 15.9022L4.23599 15.2444H4.23599L4.43332 15.9022ZM7.7129 14.9185L7.91023 15.5764L8.06286 15.5306L8.17973 15.4223L7.7129 14.9185ZM7.7385 14.8948L8.22787 14.4129L7.76038 13.9382L7.27167 14.391L7.7385 14.8948ZM7.74768 14.9041L7.25831 15.386L7.74019 15.8754L8.22957 15.3935L7.74768 14.9041ZM16.7076 6.08142L16.2257 5.59204L16.7076 6.08142ZM16.7238 3.99178L16.2344 4.47367L16.2344 4.47367L16.7238 3.99178ZM14.7024 4.045C15.0136 3.73856 15.5143 3.74242 15.8208 4.05363L16.7995 3.08986C15.9608 2.23809 14.5904 2.22751 13.7386 3.06623L14.7024 4.045ZM5.74246 12.8677L14.7024 4.045L13.7386 3.06623L4.77868 11.8889L5.74246 12.8677ZM5.75743 11.904L5.74995 11.8964L4.77118 12.8602L4.77866 12.8678L5.75743 11.904ZM4.77009 11.9129L4.75273 11.9312L5.74863 12.8772L5.766 12.8589L4.77009 11.9129ZM4.59368 12.204L3.58618 15.5111L4.90018 15.9114L5.90768 12.6043L4.59368 12.204ZM3.58618 15.5111C3.39083 16.1524 3.9886 16.7527 4.63065 16.5601L4.23599 15.2444C4.64427 15.1219 5.0244 15.5037 4.90018 15.9114L3.58618 15.5111ZM4.63065 16.5601L7.91023 15.5764L7.51557 14.2607L4.23599 15.2444L4.63065 16.5601ZM8.17973 15.4223L8.20532 15.3986L7.27167 14.391L7.24608 14.4147L8.17973 15.4223ZM8.23706 14.4222L8.22787 14.4129L7.24912 15.3767L7.25831 15.386L8.23706 14.4222ZM16.2257 5.59204L7.2658 14.4147L8.22957 15.3935L17.1895 6.57081L16.2257 5.59204ZM16.2344 4.47367C16.5408 4.78488 16.537 5.28559 16.2257 5.59204L17.1895 6.57081C18.0413 5.73208 18.0519 4.36167 17.2131 3.5099L16.2344 4.47367ZM15.8208 4.05363L16.2344 4.47367L17.2131 3.5099L16.7995 3.08986L15.8208 4.05363Z"
                    fill="#27D003"
                    mask="url(#path-1-inside-1_4750_401)"
                  />
                  <path
                    d="M13.3247 4.45898L15.7974 6.96927"
                    stroke="#27D003"
                    strokeWidth="0.686813"
                  />
                  <path
                    d="M2.85107 16.8691L13.3247 16.8315"
                    stroke="#27D003"
                    strokeWidth="0.686813"
                    strokeLinecap="round"
                  />
                </svg>
                Редактировать
              </div>
              {openEdit ? (
                <ul>
                  <li
                    onClick={() =>
                      navigate(`/wiki/my_articles/edit/${article.id}`)
                    }
                  >
                    <span>Внести изменения</span>
                  </li>
                  <li onClick={() => setIsDelete(true)}>
                    <span>Удалить статью</span>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {isDelete && (
        <ModalOneButton
          title="Удаление статьи"
          description="Вы действительно хотите удалить статью? Отменить данное действие будет невозможно."
          buttonStyle={
            isMobile
              ? {
                  width: "100%",
                  padding: `${vhM(13.5)} ${vwM(27)}`,
                  fontSize: vhM(16),
                  lineHeight: vhM(21),
                }
              : {
                  width: "100%",
                  padding: `${vh(13.5)} ${vw(27)}`,
                  fontSize: vh(16),
                  lineHeight: vh(21),
                }
          }
          button={{
            title: "Удалить",
            action: () => {
              setIsDelete(false);
              removeArticle();
            },
          }}
          close={() => setIsDelete(false)}
        />
      )}
    </div>
  );
};

export { Article };
