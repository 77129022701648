import styles from "./NewProduct.module.scss";
import star from "assets/img/new_star.svg";
import new_star_empty from "assets/img/new_star_empty.svg";
import EmptyProduct from "assets/img/empty_product.png";
import { Link, Button, CounterItem } from "components";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { axiosFavorite, axiosBasket } from "plugins/axios";
import { checkError } from "plugins/checkError";
import { ToastContainer, toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";

const NewProduct = ({
  item,
  isDefaultStyle = true,
  slider = false,
  imgStyle = {},
  purchase = false,
  purchaseOpenModel = null,
  purchaseOpenChatModel = null,
  purchaseReturn = null,
  purchaseStatus = false,
  marginRight = "0px",
  ...rest
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const basketItem = JSON.parse(localStorage.getItem("basket"))?.find(
    (el) => el.id === item.id
  );
  const favoriteItem = JSON.parse(
    localStorage.getItem("favorite_products")
  )?.find((el) => el === item.id);
  const [inFavorite, setInFavorite] = useState(
    localStorage.getItem("token")
      ? item.in_favorite
      : favoriteItem !== undefined
  );
  const [inBasket, setInBasket] = useState(
    localStorage.getItem("token") ? !!item.count_in_basket : !!basketItem?.count
  );
  const [count, setCount] = useState(
    localStorage.getItem("token")
      ? item.count_in_basket
        ? item.count_in_basket
        : 1
      : basketItem?.count || 1
  );

  const morph = (mark, array) => {
    return (
      (array = array || ["оценка", "оценки", "оценок"]) &&
      array[
        mark % 100 > 4 && mark % 100 < 20
          ? 2
          : [2, 0, 1, 1, 1, 2][mark % 10 < 5 ? mark % 10 : 5]
      ]
    );
  };

  const addToBasket = async () => {
    if (!inBasket) {
      if (localStorage.getItem("token")) {
        let res = await axiosBasket.addToCart(item.id);
        let { isError, messageError } = checkError(res);
        if (isError) {
          toast.info(messageError, {
            position: "bottom-center",
            autoClose: 5000,
            theme: "light",
          });
          setInBasket(false);
          return;
        }
        setInBasket(true);
      } else {
        let newBasket = localStorage.getItem("basket")
          ? [
              { id: item.id, count: 1 },
              ...JSON.parse(localStorage.getItem("basket")),
            ]
          : [{ id: item.id, count: 1 }];
        localStorage.setItem("basket", JSON.stringify(newBasket));
        setInBasket(true);
      }
    } else {
      await deleteProductFromCart();
    }
    await axiosBasket.getCountInMyCart();
  };

  const changeProductCount = async (value) => {
    if (value > 0) {
      if (localStorage.getItem("token")) {
        await axiosBasket.changeCartProductCount(item.id, value);
      } else {
        let newBasket = localStorage.getItem("basket")
          ? JSON.parse(localStorage.getItem("basket")).map((e) =>
              e.id === item.id ? { ...e, count: value } : e
            )
          : [];
        localStorage.setItem("basket", JSON.stringify(newBasket));
      }
      setCount(value);
    } else {
      await deleteProductFromCart();
    }
    await axiosBasket.getCountInMyCart();
  };

  const deleteProductFromCart = async () => {
    if (localStorage.getItem("token")) {
      let res = await axiosBasket.removeFromCart(item.id);
      let { isError, messageError } = checkError(res);
      if (isError) {
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        setInBasket(true);
        return;
      }
    } else {
      let newBasket = localStorage.getItem("basket")
        ? JSON.parse(localStorage.getItem("basket")).filter(
            (e) => e.id !== item.id
          )
        : [];
      localStorage.setItem("basket", JSON.stringify(newBasket));
    }
    setCount(1);
    setInBasket(false);
  };

  const addOrRemoveFavorite = async () => {
    if (localStorage.getItem("token")) {
      let resp = null;
      if (inFavorite) {
        resp = await axiosFavorite.removeFromFavorite(item.id);
      } else {
        resp = await axiosFavorite.addToFavorite(item.id);
      }
      let { isError, messageError } = checkError(resp);
      if (isError) {
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        return;
      }
    } else {
      if (inFavorite) {
        let newFavorite = JSON.parse(
          localStorage.getItem("favorite_products")
        ).filter((e) => e !== item.id);
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      } else {
        let newFavorite = localStorage.getItem("favorite_products")
          ? [item.id, ...JSON.parse(localStorage.getItem("favorite_products"))]
          : [item.id];
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      }
    }
    if (inFavorite) {
      setInFavorite(false);
    } else {
      setInFavorite(true);
    }
  };

  return (
    <div
      style={{ margin: `0.5vh ${marginRight} 0.5vh 0`, position: "relative" }}
    >
      <ToastContainer containerId={item.id} />
      <div
        className={styles.sale}
        style={{ opacity: item.discount_type ? 1 : 0 }}
      >
        -{item.discount_value}
        {item.discount_type === "MN" ? "₽" : "%"}
      </div>
      <div
        className={isDefaultStyle ? styles.product : styles.new_style_product}
        style={{ ...rest }}
      >
        {!purchase && (
          <div
            title={
              inFavorite ? "Удалить из Избранного" : "Добавить в Избранное"
            }
            className={`${styles.heart} ${
              inFavorite ? styles.heart_active : styles.heart_not_active
            } ${slider && styles.slider_heart}`}
            onClick={addOrRemoveFavorite}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={isMobile ? vwM(30) : vw(30)}
              height={isMobile ? vhM(31) : vh(31)}
              viewBox="0 0 30 31"
              fill="none"
            >
              <path
                d="M14.4938 8.72083L14.9286 9.54409L15.3633 8.72082C16.5684 6.43903 18.4704 4.90964 20.9888 4.90964C24.6466 4.90964 27.3655 7.96606 27.3655 11.7844C27.3655 14.2776 26.1809 16.3394 24.0238 18.6037C22.5281 20.1738 20.6111 21.7964 18.3618 23.7002C17.3416 24.5637 16.2531 25.485 15.1045 26.4856C15.0039 26.5732 14.8531 26.5732 14.7526 26.4856C13.6048 25.4858 12.5168 24.5649 11.4969 23.7017C9.24896 21.799 7.332 20.1765 5.83581 18.6061C3.67784 16.3411 2.49167 14.2778 2.49167 11.7844C2.49167 7.96545 5.211 4.90964 8.8683 4.90964C11.3867 4.90964 13.2887 6.43888 14.4938 8.72083Z"
                fill="white"
                stroke="#BFC4C3"
                strokeWidth="0.983342"
              />
            </svg>
          </div>
        )}
        <div className={styles.picture}>
          <Link path={`/${item?.price ? "product" : "service"}/${item.id}`}>
            <img
              src={(item.pic_urls && item.pic_urls[0]?.pic_url) || EmptyProduct}
              alt={item.name}
              style={{ ...imgStyle }}
              onError={(e) => (e.target.src = EmptyProduct)}
            />
          </Link>
        </div>

        {purchase && (
          <Rating
            onClick={(rate) => purchaseOpenModel(rate)}
            initialValue={item.product_mark}
            readonly={!!item.product_mark}
            transition
            titleSeparator="из"
            fillIcon={
              <img
                src={star}
                width={20}
                height={20}
                style={{ marginRight: "5px" }}
                alt="star"
              />
            }
            emptyIcon={
              <img
                src={new_star_empty}
                width={20}
                height={20}
                style={{ marginRight: "5px" }}
                alt="star"
              />
            }
          />
        )}
        <Link
          path={`/${item?.price ? "product" : "service"}/${item.id}`}
          className={styles.name}
        >
          {item.name}
        </Link>
        {!purchase && (
          <div className={styles.review}>
            <img src={star} alt="Звезда" />
            <p>
              {item.product_mark} ({item.mark_count} {morph(item.mark_count)})
            </p>
          </div>
        )}
        {purchase ? (
          <div className={styles.price_purchase}>
            <div style={{ marginRight: "5px" }}>{item.price}&nbsp;₽</div>
            <div className={styles.price}>
              {purchaseStatus ? (
                <Button
                  text="Возврат"
                  padding={isMobile ? "2vh 0vw" : "1vh 0.7vw"}
                  width={isMobile && "100%"}
                  click={purchaseReturn}
                />
              ) : inBasket ? (
                <CounterItem
                  count={count}
                  smallButton
                  change={changeProductCount}
                />
              ) : (
                <Button
                  text="Купить"
                  padding={isMobile ? "2vh 0vw" : "0.6vh 0.9vw"}
                  width={isMobile && "100%"}
                  click={addToBasket}
                />
              )}
              <Button
                text="Чат с продовцом"
                padding={isMobile ? "2vh 0vw" : "1vh 0.5vw"}
                marginTop={isMobile && "1vh"}
                width={isMobile && "100%"}
                bgColor="#EAF8E7"
                color="#20B900"
                click={purchaseOpenChatModel}
              />
            </div>
          </div>
        ) : (
          <>
            <div className={styles.price}>
              <div
                className={item.discount_type ? styles.new_price : ""}
                style={{ marginRight: "5px" }}
              >
                {item.discount_type && <>{item.discount_price}&nbsp;₽</>}
                <p className={item.discount_type ? styles.old_price : ""}>
                  {item.price}&nbsp;₽
                </p>
              </div>
              {!isMobile && (
                <div>
                  {inBasket ? (
                    <CounterItem
                      count={count}
                      smallButton
                      change={changeProductCount}
                    />
                  ) : (
                    <Button
                      text="Купить"
                      padding="0.6vh 0.9vw"
                      click={addToBasket}
                    />
                  )}
                </div>
              )}
            </div>
            {isMobile && (
              <div>
                {inBasket ? (
                  <CounterItem
                    count={count}
                    smallButton
                    marginTop={vhM(10)}
                    width="fit-content"
                    change={changeProductCount}
                  />
                ) : (
                  <Button
                    text="Купить"
                    padding={`${vhM(9)} ${vwM(18)}`}
                    fontSize={vhM(14)}
                    lineHeight={vhM(16)}
                    marginTop={vhM(10)}
                    width="100%"
                    click={addToBasket}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { NewProduct };
