export const MAIN = { url: "/", title: "Главная" };

//----------

export const BASKET = { url: "/basket", title: "Корзина" };
export const FAVORITE = { url: "/favorite", title: "Избранное" };

//----------

export const PROFILE = { url: "/profile", title: "Профиль" };
export const SELLER_PROFILE = { url: "/seller", title: "Профиль продавца" };
export const FORGOT_PASSWORD = {
  url: "/forgot_password",
  title: "Забыли пароль",
};
export const RESET_PASSWORD = {
  url: "/reset_password",
  title: "Восстановить пароль",
};
export const YANDEX = { url: "/yandex", title: "Яндекс" };
export const WAIT_FOR_VERIFICATION = {
  url: "/wait_for_verification",
  title: "Ожидание проверки",
};
export const BECOME_SELLER = {
  url: "/seller/becomeseller",
  title: "Стать продавцом",
  typeOfInfo: "becomeseller",
};

//----------

export const CREATE_PRODUCT = {
  url: "/seller/createproduct",
  title: "Создание товара",
};
export const CREATE_SERVICE = {
  url: "/seller/createservice",
  title: "Создание услуги",
};

export const UPDATE_PRODUCT = {
  url: "/seller/upduteproduct/:id",
  title: "Редактрирование товара",
};
export const UPDATE_SERVICE = {
  url: "/seller/upduteservice/:id",
  title: "Редактрирование услуги",
};

export const SHOW_MY_SERVICE = {
  url: "/seller/showservice/:id",
  title: "Просмотр услуги",
};
export const SHOW_MY_PRODUCT = {
  url: "/seller/showproduct/:id",
  title: "Просмотр услуги",
};

//----------

export const SERVICE_LIST = { url: "/services", title: "Услуги" };
export const CATALOGUE_ID = { url: "/:catalogue/:tab", title: "" };
export const PRODUCT_ID = { url: "/product/:id", title: "Товар" };
export const ALL_REVIEWS = {
  url: "/product/allreviews/:id",
  title: "Все отзывы",
};
export const SERVICE_ID = { url: "/service/:id", title: "Услуга" };

//----------

export const QUESTIONNAIRE = { url: "/questionnaire", title: "Анкета" };
export const QUESTIONNAIRE_RESULT = {
  url: "/questionnaire-result",
  title: "Результат анкетирования",
};

//----------

//Static page
export const PARTNERS_TYPE = {
  url: "/partners/:typeOfInfo",
  title: "Партнёрам",
};
export const BUYERS_TYPE = { url: "/buyers/:typeOfInfo", title: "Покупателям" };
export const SHALFEY_TYPE = { url: "/shalfey/:typeOfInfo", title: "Шалфей" };
export const ABOUT = { url: "/about", title: "О нас" };
export const SELLERS = {
  url: "/sellers",
  title: "Продавцам",
  typeOfInfo: "sellers",
};
export const SHALFEY = { url: "/shalfey", title: "Шалфей" };
export const SHALFEY_WIKI = { url: "/shalfey", title: "Шалфей.Вики" };
export const PARTNERS = {
  url: "/partners",
  title: "Стать продавцом",
  typeOfInfo: "sellers",
};
export const INSTRACTION = {
  url: "/seller/instraction",
  title: "Стать продавцом",
};
export const BUYERS = {
  url: "/buyers",
  title: "Покупателям",
  typeOfInfo: "customer",
};
export const HOW_TO_MAKE_ORDER = {
  url: "/how_to_make_order",
  title: "Как сделать заказ?",
  typeOfInfo: "order",
};
export const PAYMENTS_METHODS = {
  url: "/payments_methods",
  title: "Способы оплаты",
  typeOfInfo: "payment",
};
export const RULES = {
  url: "/rules",
  title: "Правила пользования магазином",
  typeOfInfo: "rules",
};
export const HOW_TO_SELL = {
  url: "/seller/instraction",
  title: "Как стать продавцом?",
  typeOfInfo: "became_seller",
};
export const SALES_RULES = {
  url: "/sales_rules",
  title: "Правила продажи",
  typeOfInfo: "selling",
};
export const DELIVERY = {
  url: "/delivery",
  title: "А доставка?",
  typeOfInfo: "delivering",
};
export const ABOUT_US = {
  url: "/about",
  title: "О нас",
  typeOfInfo: "about",
};
export const PAYMENT_DETAILS = {
  url: "/payment_details",
  title: "Реквизиты",
  typeOfInfo: "requisites",
};
export const DOCUMENTS = {
  url: "/documents",
  title: "Документы",
  typeOfInfo: "documents",
};
export const CONTACTS = {
  url: "/contacts",
  title: "Контакты",
  typeOfInfo: "contacts",
};
//----------------

//Wiki
export const ARTICLES = {
  url: "/wiki/articles",
  title: "Шалфей.Вики",
};
export const ARTICLE_ID = {
  url: "/wiki/articles/:id",
};
export const CREATE_ARTICLE = {
  url: "/wiki/create",
  title: "Создание статьи",
};
export const MY_ARTICLES = {
  url: "/wiki/my_articles",
  title: "Мои статьи",
};
export const EDIT_ARTICLE_ID = {
  url: "/wiki/my_articles/edit/:id",
};
//------------------

//My shop
export const CHAT_WITH_BUYERS = {
  url: "/seller/shop/chat",
  title: "Мой магазин - чат с покупателями",
};
export const SHOP_PRODUCTS = {
  url: "/seller/shop/products",
  title: "Мой магазин - товары",
};
export const SHOP_REVIEWS = {
  url: "/seller/shop/reviews",
  title: "Мой магазин - отзывы",
};
export const SHOP_REVIEWS_ID = {
  url: "/seller/shop/reviews/:id",
  title: "Мой магазин - отзывы",
};

//----------------

//Deliveries
export const ORDERS_HANDLER = {
  url: "/seller/deliveries/orders_handler",
  title: "Управление заказами",
};
export const WAREHOUSE = {
  url: "/seller/deliveries/warehouse",
  title: "Склады",
};
export const CANCELED_ORDERS = {
  url: "/seller/deliveries/canceled_orders",
  title: "Склады",
};
export const TRACKING_ORDERS = {
  url: "/seller/deliveries/tracking_orders",
  title: "Отслеживание статуса заказа",
};

//Analytics
export const TREND_ANALYTICS = {
  url: "/seller/analytics/trend_analytics",
  title: "Трендовая аналитика",
};
export const SERVICES_ANALYTICS = {
  url: "/seller/analytics/services_analytics",
  title: "Аналитика по услугам",
};
export const SERVICES_ANALYTICS_ID = {
  url: "/seller/analytics/services_analytics/:id",
  title: "Аналитика по услугам",
};
export const SALES_FUNNEL = {
  url: "/seller/analytics/sales_funnel",
  title: "Воронка продаж",
};
export const FINANCIAL_STATEMENTS = {
  url: "/seller/analytics/financial_statements",
  title: "Финансовая отчетность",
};
export const MARKETING_ANALYTICS = {
  url: "/seller/analytics/marketing_analytics",
  title: "Маркетинговая аналитика",
};
export const CLIENTS = {
  url: "/seller/analytics/clients",
  title: "Клиенты",
};

//----------------

export const CHAT_WITH_SELLERS = {
  url: "/profile/chats",
  title: "Профиль - Чаты",
};
export const SELLER_NOTIFICATIONS = {
  url: "/seller/notifications",
  title: "Уведомления",
};

//----------------

//Promotions and Discount
export const PROMOTIONS_DISCOUNT = {
  url: "/seller/deliveries/promotions_discount/:type",
  title: "Акции и скидки",
};

//Новости
export const NEWS = {
  url: "/wiki/news",
  title: "Новости",
};
export const NEWS_ID = {
  url: "/wiki/news/:id",
  title: "Новости",
};

//Форум
export const FORUM = {
  url: "/wiki/forum",
  title: "Форум",
};
export const FORUM_ID = {
  url: "/wiki/forum/:id",
  title: "Форум",
};

export const NOT_FOUND_PAGE = { url: "*", title: "Ошибка" };

export const STATIC_PAGES = [
  PARTNERS,
  BUYERS,
  HOW_TO_MAKE_ORDER,
  PAYMENTS_METHODS,
  RULES,
  HOW_TO_SELL,
  SALES_RULES,
  DELIVERY,
  ABOUT_US,
  PAYMENT_DETAILS,
  DOCUMENTS,
  CONTACTS,
  SELLERS,
];
