import styles from "./Basket.module.scss";
import React, { useState, useEffect, useRef } from "react";
import {
  axiosBasket,
  axiosFavorite,
  axiosHome,
  axiosUserProfile,
  axiosStatic,
} from "plugins/axios";
import {
  Checkbox,
  CounterItem,
  Link,
  Button,
  SliderProducts,
  ModalOneButton,
  ModalTwoButtons,
  ModalNoButton,
  Input,
} from "components";
import emptyProduct from "assets/img/empty_product.png";
import russian_post from "assets/img/russian_post.svg";
import pdp from "assets/img/pdp.svg";
import edit from "assets/img/edit.svg";

import { useOutsideClick, useKeyDown } from "hooks";
import store from "../../redux/stores";
import {
  CHANGE_IS_OPEN_AUTH_MODAL,
  CHANGE_COUNT_IN_BASKET,
} from "constants/actionType";
import { useSelector } from "react-redux";
import { STREETS } from "constants/streetType";

import { checkError } from "plugins/checkError";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Basket = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const countInBasket = useSelector((state) => state.countInBasket);

  const [products, setProducts] = useState([]);
  const [hits, setHits] = useState([]);
  const [orderPrice, setOrderPrice] = useState({
    orderPrice: 0,
    priceOfGoods: 0,
  });
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [idForDelete, setIdForDelete] = useState(-1);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [typeOfDelivery, setTypeOfDelivery] = useState(false);
  const [orderInfo, setOrderInfo] = useState({
    fio: "",
    phone: "",
    city: "",
    type_street: "Улица",
    street: "",
    house: "",
    vlad: "",
    apartment: "",
    office: "",
    structure: "",
    block: "",
    post_index: "",
    terminal: 0,
    delivery_type: "",
    delivery_service: "",
  });
  const [error, setError] = useState({
    fio: "",
    phone: "",
    city: "",
    type_street: "",
    street: "",
    house: "",
    vlad: "",
    apartment: "",
    office: "",
    structure: "",
    block: "",
    post_index: "",
    terminal: "",
    errorMessage: "",
  });
  const [messageInfo, setMessageInfo] = useState("");

  const debounceTimeout = useRef(null);
  const debounceCityTimeout = useRef(null);
  const [addressText, setAddressText] = useState("");
  const [cityText, setCityText] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [cities, setСities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchCity, setIsSearchCity] = useState(false);
  const [isAddressSelected, setAddressSelected] = useState(false);
  const [isCitySelected, setCitySelected] = useState(true);

  const [modalLink, setModalLink] = useState(false);
  const [payLink, setPayLink] = useState("");
  const [sum, setSum] = useState(-1);
  const [openCity, setOpenCity] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const refSelect = useOutsideClick(() => setOpenSelect(false));

  const getCitiesTerminals = async () => {
    if (cityText) {
      let resCities = await axiosStatic.getTerminals(cityText, addressText);
      setAddresses(resCities);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (isSearch) {
      getCitiesTerminals();
      setIsSearch(false);
    } else {
      debounceTimeout.current = setTimeout(() => getCitiesTerminals(), 1500);
    }

    return () => clearTimeout(debounceTimeout.current);
  }, [cityText, addressText, isSearch]);

  const getCities = async () => {
    let result = await axiosStatic.getCities(cityText);
    setСities(result || []);
    setIsLoading(false);
  };

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceCityTimeout.current);

    if (isSearchCity) {
      getCities();
      setIsSearchCity(false);
    } else {
      debounceCityTimeout.current = setTimeout(() => getCities(), 1500);
    }

    return () => clearTimeout(debounceCityTimeout.current);
  }, [cityText, isSearchCity]);

  useKeyDown(() => {
    setIdForDelete(-1);
  }, ["Escape"]);

  useEffect(() => {
    const getMyCart = async () => {
      let newRes = [];
      if (localStorage.getItem("token")) {
        let res = await axiosBasket.getMyCart();
        let { isError, messageError } = checkError(res);
        if (!isError) {
          newRes = res?.items.map((e) => ({ ...e, isSelected: true })) || [];
          setProducts(newRes);
        }
      } else {
        let newProductIDs =
          JSON.parse(localStorage.getItem("basket"))?.map((e) => e.id) || [];
        let res = await axiosBasket.getMyCartForUnauth({
          products: newProductIDs,
        });
        let { isError, messageError } = checkError(res);
        if (!isError) {
          let inFavorite =
            JSON.parse(localStorage.getItem("favorite_products")) || [];
          let basketFromStorage = JSON.parse(localStorage.getItem("basket"));
          newRes =
            res?.products.map((e) => ({
              id: e.id,
              product: e,
              isSelected: true,
              in_favorite: inFavorite.includes(e.id),
              count: basketFromStorage.find((i) => i.id === e.id).count || 1,
            })) || [];
          setProducts(newRes);
        }
      }
      setSelectedAll(true);
      setOrderPrice(calcOrderPrice(newRes));
      let result = await axiosHome.getHits();
      setHits(
        result.purchased.map((e) => ({ ...e, count_in_basket: 0 })) || []
      );
    };
    getMyCart();
  }, [countInBasket]);

  const changeSelected = (id, newValue) => {
    let newProducts = products.map((e) =>
      e.id === id ? { ...e, isSelected: newValue } : e
    );

    setSelectedAll(checkUnselected(newProducts));
    setProducts(newProducts);
    setOrderPrice(calcOrderPrice(newProducts));
  };
  const changeSelectedAll = (newValue) => {
    let newProducts = products.map((e) => ({ ...e, isSelected: newValue }));

    setSelectedAll(newValue);
    setProducts(newProducts);
    setOrderPrice(calcOrderPrice(newProducts));
  };

  const favorite = async (id) => {
    let product = products.find((e) => e.id === id);
    if (!product) return;
    if (localStorage.getItem("token")) {
      let res = await (product.in_favorite
        ? axiosFavorite.removeFromFavorite(product.product.id)
        : axiosFavorite.addToFavorite(product.product.id));
      let { isError, messageError } = checkError(res);
      if (isError) {
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        return;
      }
    } else {
      if (product.in_favorite) {
        let newFavorite = JSON.parse(
          localStorage.getItem("favorite_products")
        ).filter((e) => e !== product.id);
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      } else {
        let newFavorite = localStorage.getItem("favorite_products")
          ? [
              product.id,
              ...JSON.parse(localStorage.getItem("favorite_products")),
            ]
          : [product.id];
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      }
    }
    setProducts(
      products.map((e) =>
        e.id === product.id ? { ...e, in_favorite: !e.in_favorite } : e
      )
    );
  };
  const tryRemoveProduct = (id) => {
    setIdForDelete(id);
  };

  const removeProduct = async (id) => {
    let product = products.find((e) => e.id === id);
    if (!product) return;
    if (localStorage.getItem("token")) {
      let res = await axiosBasket.removeFromCart(product.product.id);
      let { isError, messageError } = checkError(res);
      if (isError) {
        setIdForDelete(-1);
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        return;
      }
    } else {
      let newBasket = localStorage.getItem("basket")
        ? JSON.parse(localStorage.getItem("basket")).filter(
            (e) => e.id !== product.id
          )
        : [];
      localStorage.setItem("basket", JSON.stringify(newBasket));
    }
    let newProducts = products.filter((e) => e.id !== product.id);
    store.dispatch({
      type: CHANGE_COUNT_IN_BASKET,
      countInBasket:
        newProducts.reduce((sum, product) => sum + product.count, 0) || 0,
    });
    setIdForDelete(-1);
    setSelectedAll(checkUnselected(newProducts));
    setProducts(newProducts);
    setOrderPrice(calcOrderPrice(newProducts));
  };

  const changeCountProduct = async (id, newValue) => {
    let product = products.find((e) => e.id === id);
    if (!product) return;
    if (newValue <= 0) {
      tryRemoveProduct(id);
    } else {
      if (localStorage.getItem("token")) {
        let res = await axiosBasket.changeCartProductCount(
          product.product.id,
          newValue
        );
        let { isError, messageError } = checkError(res);
        if (isError) {
          toast.info(messageError, {
            position: "bottom-center",
            autoClose: 5000,
            theme: "light",
          });
          return;
        }
      } else {
        let newBasket = localStorage.getItem("basket")
          ? JSON.parse(localStorage.getItem("basket")).map((e) =>
              e.id === product.id ? { ...e, count: newValue } : e
            )
          : [];
        localStorage.setItem("basket", JSON.stringify(newBasket));
      }
      let newProducts = products.map((e) =>
        e.id === product.id ? { ...e, count: newValue } : e
      );

      store.dispatch({
        type: CHANGE_COUNT_IN_BASKET,
        countInBasket:
          newProducts.reduce((sum, product) => sum + product.count, 0) || 0,
      });
      setProducts(newProducts);
      setOrderPrice(calcOrderPrice(newProducts));
    }
  };
  const returnToMain = () => {
    navigate("/");
  };

  const getSum = async () => {
    let res = null;
    if (orderInfo.delivery_type === "terminal" && orderInfo.terminal !== 0) {
      res = await axiosBasket.getSum(
        "?delivery_type=" +
          orderInfo.delivery_type +
          "&city=" +
          orderInfo?.city.trim() +
          "&cart_ids=" +
          products
            .filter((product) => product.isSelected)
            .map((product) => product.id) +
          "&terminal=" +
          orderInfo.terminal
      );
      let { isError, messageError } = checkError(res);
      if (isError) {
        setSum(-1);
        return;
      }
      setSum(res);
    } else if (
      orderInfo.delivery_type === "courier" &&
      orderInfo?.city &&
      orderInfo?.post_index
    ) {
      res = await axiosBasket.getSum(
        "?delivery_type=" +
          orderInfo.delivery_type +
          "&city=" +
          orderInfo?.city.trim() +
          "&cart_ids=" +
          products
            .filter((product) => product.isSelected)
            .map((product) => product.id) +
          "&index=" +
          orderInfo?.post_index
      );
      let { isError, messageError } = checkError(res);
      if (isError) {
        setSum(-1);
        return;
      }
      setSum(res);
    } else if (
      orderInfo.delivery_service === "pochta" &&
      orderInfo?.post_index
    ) {
      res = await axiosBasket.getSum(
        "?delivery_service=" +
          orderInfo.delivery_service +
          "&cart_ids=" +
          products
            .filter((product) => product.isSelected)
            .map((product) => product.id) +
          "&index=" +
          orderInfo?.post_index
      );
      let { isError, messageError } = checkError(res);
      if (isError) {
        setSum(-1);
        return;
      }
      setSum(res);
    }
  };

  const openOrder = async () => {
    if (localStorage.getItem("token")) {
      if (products.some((product) => product.isSelected)) {
        let resUserInfo = await axiosUserProfile.getProfileDetails();
        setOrderInfo({
          ...orderInfo,
          fio: resUserInfo?.fio || "",
          phone: resUserInfo?.phone?.replaceAll(" ", "") || "",
          city: resUserInfo?.city || "",
          post_index: resUserInfo?.post_index || "",
        });
        setCityText(resUserInfo?.city);
        getCitiesTerminals();
        setOpenOrderModal(true);
        setError({
          fio: "",
          phone: "",
          city: "",
          type_street: "",
          street: "",
          house: "",
          vlad: "",
          apartment: "",
          office: "",
          structure: "",
          block: "",
          post_index: "",
          terminal: "",
          errorMessage: "",
        });
      } else {
        setMessageInfo("Выберите хотя бы один товар");
      }
    } else {
      setShowOrderModal(true);
    }
  };

  useEffect(() => {
    getSum();
  }, [
    orderInfo.delivery_type,
    orderInfo.post_index,
    orderInfo?.city,
    orderInfo?.terminal,
    orderInfo?.delivery_service,
  ]);

  const openAuth = () => {
    setShowOrderModal(false);
    store.dispatch({
      type: CHANGE_IS_OPEN_AUTH_MODAL,
      isOpenAuthModal: true,
    });
  };
  const makeOrder = async () => {
    if (checkProperties()) {
      let paymentInfo = {
        ...orderInfo,
        carts: products
          .filter((product) => product.isSelected)
          .map((product) => product.id),
        type_street: orderInfo.type_street.toLowerCase(),
        post_index: orderInfo.post_index.toString(),
        delivery_price: sum,
        delivery_service: orderInfo.delivery_service,
      };
      let resPayment = await axiosBasket.getPaymentUrl(paymentInfo);
      let { isError, messageError } = checkError(resPayment);
      if (isError) {
        if (resPayment.response.status === 400) {
          if (
            resPayment.response.data.Error ===
            "You choose courier-type delivery, but address data were not provided"
          ) {
            setError((prev) => {
              return {
                ...prev,
                city: "Заполните поле города правильно",
                street: "Заполните поле адреса правильно",
                type_street: "Заполните поле типа адреса правильно",
                house: "Заполните поле номера дома правильно",
                post_index: "Заполните поле индекса правильно",
                errorMessage: "Заполните поля правильно",
              };
            });
          } else if (resPayment.response.data.Error === "Incorrect address") {
            setError((prev) => {
              return {
                ...prev,
                city: "Заполните поле города правильно",
                street: "Заполните поле адреса правильно",
                type_street: "Заполните поле типа адреса правильно",
                house: "Заполните поле номера дома правильно",
                post_index: "Заполните поле индекса правильно",
                vlad: !!paymentInfo.vlad && "Заполните поле владение правильно",
                apartment:
                  !!paymentInfo.apartment &&
                  "Заполните поле квартиры правильно",
                office:
                  !!paymentInfo.office && "Заполните поле офиса правильно",
                structure:
                  !!paymentInfo.structure &&
                  "Заполните поле строения правильно",
                block: !!paymentInfo.block && "Заполните поле блока правильно",
                errorMessage: "Заполните поля правильно",
              };
            });
          }
        } else {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: messageError,
            };
          });
        }
      } else {
        localStorage.removeItem("basket");
        setProducts([]);
        setOpenOrderModal(false);
        const newWindow = window.open("", "_blank");
        if (newWindow) {
          newWindow.location.href = resPayment;
        } else {
          setModalLink(true);
          setPayLink(resPayment);
        }
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Заполните поля правильно",
        };
      });
    }
  };

  const checkProperties = () => {
    let valid = true;
    let error_check = {
      fio: "",
      phone: "",
      city: "",
      type_street: "",
      street: "",
      house: "",
      vlad: "",
      apartment: "",
      office: "",
      structure: "",
      block: "",
      post_index: "",
      terminal: "",
      errorMessage: "",
    };
    let optional_fields = ["apartment", "vlad", "office", "structure", "block"];
    if (orderInfo.delivery_type === "terminal") {
      optional_fields.push(...["street", "house"]);
    }
    for (let key in orderInfo) {
      if (
        (orderInfo[key] === null || orderInfo[key] === "") &&
        !optional_fields.includes(key)
      ) {
        valid = false;
        error_check[key] = "Введите корректные данные";
      }
    }
    setError(() => error_check);
    if (!/^[\d\+][\d\(\)\ -]{4,14}\d$/.test(orderInfo.phone)) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          phone: "Неверный формат",
        };
      });
    }
    if (sum === -1) {
      valid = false;
    }
    if (orderInfo.delivery_type === "terminal" && orderInfo.terminal === 0) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          terminal: "Выберите терминал",
        };
      });
    }
    return valid;
  };

  const InputsForOrder = (
    <>
      {typeOfDelivery ? (
        <div>
          <div>
            <label
              className={styles.type_label}
              style={{
                backgroundColor:
                  orderInfo.delivery_type === "courier" ? "#EFFAF0" : "#F8FBF9",
              }}
            >
              <Checkbox
                isSelected={orderInfo.delivery_type === "courier"}
                width={isMobile ? vhM(14) : vh(14)}
                height={isMobile ? vhM(14) : vh(14)}
                styleBack={{
                  padding: `${vh(2)} ${vw(2)}`,
                  borderRadius: vh(2.69),
                }}
                change={() => {
                  setOrderInfo({ ...orderInfo, delivery_type: "courier" });
                  setSum(-1);
                }}
              />
              <span>Доставка курьером</span>
            </label>
            <label
              className={styles.type_label}
              style={{
                backgroundColor:
                  orderInfo.delivery_type === "terminal"
                    ? "#EFFAF0"
                    : "#F8FBF9",
              }}
            >
              <Checkbox
                isSelected={orderInfo.delivery_type === "terminal"}
                width={isMobile ? vhM(14) : vh(14)}
                height={isMobile ? vhM(14) : vh(14)}
                styleBack={{
                  padding: `${vh(2)} ${vw(2)}`,
                  borderRadius: vh(2.69),
                }}
                change={() => {
                  setOrderInfo({ ...orderInfo, delivery_type: "terminal" });
                  setSum(-1);
                  setOpenCity(true);
                }}
              />
              <span>Самовывоз из почтомата</span>
            </label>
          </div>
          {orderInfo.delivery_type === "terminal" ? (
            <div className={styles.search}>
              <Input
                white
                errorMessage={error.terminal}
                placeholder="Выберите почтомат"
                value={addressText}
                fontColor="#5F6B69"
                height={vh(50)}
                width="100%"
                borderBottom="1px solid #5F6B6980"
                inputStyles={{
                  fontSize: isMobile ? vh(14) : vh(16),
                  lineHeight: isMobile ? vhM(18) : vh(20),
                }}
                prepend={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={isMobile ? vhM(12) : vh(12)}
                    height={isMobile ? vhM(13) : vh(13)}
                    viewBox="0 0 12 13"
                    fill="none"
                    className={openCity ? styles.open : ""}
                    onClick={() => setOpenCity(!openCity)}
                  >
                    <path
                      d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                      stroke="#5F6B69"
                      strokeWidth="0.61828"
                      strokeLinecap="round"
                    />
                  </svg>
                }
                onChange={(ev) => {
                  setAddressSelected(false);
                  setAddressText(ev.target.value);
                  setIsLoading(true);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") setIsSearch(true);
                }}
              />
              {isLoading ? (
                <div className={styles.search__empty}>Поиск...</div>
              ) : isAddressSelected || !openCity ? (
                <></>
              ) : addresses.length ? (
                <ul className={styles.search__list}>
                  {addresses.map((address) => (
                    <li
                      key={address.id}
                      className={
                        address.address === addressText ? styles.select : null
                      }
                      onClick={() => {
                        setAddressSelected(true);
                        setAddressText(address.address);
                        setOrderInfo({ ...orderInfo, terminal: address.id });
                      }}
                    >
                      <span>{address.address}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className={styles.search__empty}>
                  Такого города не найдено
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <Input
            green
            placeholder="ФИО"
            value={orderInfo.fio}
            errorMessage={error.fio}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, fio: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Номер телефона"
            value={orderInfo.phone}
            errorMessage={error.phone}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, phone: ev.target.value })
            }
          />
          <div className={styles.search}>
            <Input
              green
              placeholder="Город"
              value={cityText}
              height={vh(50)}
              borderBottom={isCitySelected ? "none" : "1px solid #5F6B6980"}
              errorMessage={error.city}
              inputStyles={{
                fontSize: isMobile ? vhM(14) : vh(14),
                lineHeight: isMobile ? vhM(18) : vh(16),
                padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
              }}
              onChange={(ev) => {
                setCitySelected(false);
                setCityText(ev.target.value);
                setIsLoading(true);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") setIsSearchCity(true);
              }}
            />
            {isLoading ? (
              <div className={styles.search__empty}>Поиск...</div>
            ) : isCitySelected ? (
              <></>
            ) : cities.length ? (
              <ul className={styles.search__list}>
                {cities.map((address) => (
                  <li
                    key={address.name}
                    className={address.name === cityText ? styles.select : null}
                    onClick={() => {
                      setCitySelected(true);
                      setCityText(address.name);
                      setOrderInfo({ ...orderInfo, city: address.name });
                    }}
                  >
                    <span>{address.name}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <div className={styles.search__empty}>
                Такого города не найдено
              </div>
            )}
          </div>
          <div
            ref={refSelect}
            className={styles.custom_select}
            style={{ marginTop: isMobile ? vhM(18) : vh(18) }}
          >
            <button
              className={openSelect ? styles.open : null}
              onClick={() => setOpenSelect(!openSelect)}
            >
              <span>
                {orderInfo.type_street === "Улица"
                  ? "Тип улицы"
                  : orderInfo.type_street || "Тип улицы"}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vh(12)}
                height={vh(13)}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                  stroke="#5F6B69"
                  strokeWidth="0.61828"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <ul className={`${openSelect ? styles.open : null}`}>
              <div>
                {STREETS.map((street) => (
                  <li
                    key={street}
                    onClick={() =>
                      setOrderInfo({
                        ...orderInfo,
                        type_street: street,
                      })
                    }
                  >
                    <span
                      className={
                        orderInfo.type_street === street ? styles.select : null
                      }
                    >
                      {street}
                    </span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <Input
            green
            placeholder="Улица"
            value={orderInfo.street}
            errorMessage={error.street}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, street: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Дом"
            type="number"
            value={orderInfo.house}
            errorMessage={error.house}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, house: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Владение"
            type="number"
            value={orderInfo.vlad}
            errorMessage={error.vlad}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, vlad: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Корпус"
            value={orderInfo.block}
            errorMessage={error.block}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, block: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Строение"
            value={orderInfo.structure}
            errorMessage={error.structure}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, structure: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Квартира"
            value={orderInfo.apartment}
            errorMessage={error.apartment}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, apartment: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Офис"
            value={orderInfo.office}
            errorMessage={error.office}
            marginBottom={isMobile ? vhM(18) : vh(18)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, office: ev.target.value })
            }
          />
          <Input
            green
            placeholder="Почтовый индекс"
            value={orderInfo.post_index}
            errorMessage={error.post_index}
            marginBottom={isMobile ? vhM(35) : vh(35)}
            inputStyles={{
              fontSize: isMobile ? vhM(14) : vh(14),
              lineHeight: isMobile ? vhM(18) : vh(16),
              padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
            }}
            onChange={(ev) =>
              setOrderInfo({ ...orderInfo, post_index: ev.target.value })
            }
          />
          <div>
            {addressText && orderInfo.delivery_type === "terminal" ? (
              <div className={styles.selected_delivery_buttons}>
                <div>
                  <img src={pdp} alt={pdp} />
                  <h1>Транспортная компания DPD</h1>
                </div>
                <p>{`Самовывоз: ${addressText}`}</p>
                <div
                  onClick={() => {
                    setOrderInfo({
                      ...orderInfo,
                      delivery_type: "",
                      delivery_service: "",
                    });
                    setSum(-1);
                  }}
                  style={{ padding: "0", cursor: "pointer" }}
                >
                  <img src={edit} alt={edit} />

                  <h1>Изменить способ доставки</h1>
                </div>
              </div>
            ) : orderInfo.delivery_type === "courier" ? (
              <div className={styles.selected_delivery_buttons}>
                <div>
                  <img src={pdp} alt={pdp} />
                  <h1>Транспортная компания DPD</h1>
                </div>
                <p>Доставка курьером</p>
                <div
                  style={{ padding: "0", cursor: "pointer" }}
                  onClick={() => {
                    setOrderInfo({
                      ...orderInfo,
                      delivery_type: "",
                      delivery_service: "",
                    });
                    setSum(-1);
                  }}
                >
                  <img src={edit} alt={edit} />

                  <h1 style={{ color: "#20B900" }}>Изменить способ доставки</h1>
                </div>
              </div>
            ) : orderInfo.delivery_service === "pochta" ? (
              <div className={styles.selected_delivery_buttons}>
                <div>
                  <img src={russian_post} alt={russian_post} />
                  <h1>Почта России</h1>
                </div>
                <div
                  style={{ padding: "0", cursor: "pointer" }}
                  onClick={() => {
                    setOrderInfo({
                      ...orderInfo,
                      delivery_type: "",
                      delivery_service: "",
                    });
                    setSum(-1);
                  }}
                >
                  <img src={edit} alt={edit} />

                  <h1 style={{ color: "#20B900" }}>Изменить способ доставки</h1>
                </div>
              </div>
            ) : (
              <div className={styles.delivery_buttons}>
                <div
                  onClick={() => {
                    setOrderInfo({ ...orderInfo, delivery_service: "pochta" });
                  }}
                >
                  <img src={russian_post} alt={russian_post} />
                  <h1>Почта России</h1>
                </div>
                <div
                  onClick={() => {
                    setTypeOfDelivery(true);
                    setOrderInfo({ ...orderInfo, delivery_service: "dpd" });
                  }}
                >
                  <img src={pdp} alt={pdp} />
                  <h1>Транспортная компания DPD</h1>
                </div>
              </div>
            )}
          </div>

          {orderInfo.delivery_type === "terminal" ? (
            <div className={styles.search}>
              {isAddressSelected && (
                <div className={styles.order_total}>
                  {sum === -1 ? (
                    <div>
                      Подсчет... Проверьте, что правильно заполнены поля Город и
                      Индекс для расчета доставки
                    </div>
                  ) : (
                    <>
                      <div className={styles.order_total__price}>
                        Сумма заказа
                      </div>
                      <div className={styles.order_delivery__price}>
                        {(sum + orderPrice.priceOfGoods).toFixed(2)} ₽
                      </div>
                      <div className={styles.order_total__items}>
                        <span>Стоимость доставки</span>
                        <span>{sum} ₽</span>
                      </div>
                      <div className={styles.order_total__items}>
                        <span>Стоимость товаров</span>
                        <span>{orderPrice.priceOfGoods.toFixed(2)} ₽</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.order_total}>
              {sum === -1 ? (
                <div>
                  Подсчет... Проверьте, что правильно заполнены поля Город и
                  Индекс для расчета доставки
                </div>
              ) : (
                <>
                  <div className={styles.order_total__price}>Сумма заказа</div>
                  <div className={styles.order_delivery__price}>
                    {(sum + orderPrice.priceOfGoods).toFixed(2)} ₽
                  </div>
                  <div className={styles.order_total__items}>
                    <span>Стоимость доставки</span>
                    <span>{sum} ₽</span>
                  </div>
                  <div className={styles.order_total__items}>
                    <span>Стоимость товаров</span>
                    <span>{orderPrice.priceOfGoods.toFixed(2)} ₽</span>
                  </div>
                </>
              )}
            </div>
          )}
          {!!error.errorMessage && (
            <span style={{ color: "red" }}>{error.errorMessage}</span>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      <Helmet title="Корзина">
        <meta name="description" content="Shalfey : Корзина" />
        <link rel="canonical" href="/basket" />
      </Helmet>
      <ToastContainer />
      <div className={styles.basket}>
        {products.length ? (
          <>
            <div className={styles.basket__header}>
              Корзина
              <span>
                {products.reduce((sum, product) => sum + product.count, 0)}
              </span>
            </div>
            <div className={styles.basket__left_side}>
              <div className={styles.basket__select_all}>
                <label>
                  <Checkbox
                    isSelected={isSelectedAll}
                    width={isMobile ? vwM(20) : vw(20)}
                    height={isMobile ? vwM(20) : vw(20)}
                    change={changeSelectedAll}
                  />
                  <span>Выбрать всё</span>
                </label>
              </div>
              {products.length
                ? products.map((product) => (
                    <ProductInBasket
                      key={product.id}
                      id={product.id}
                      inFavorite={product.in_favorite}
                      count={product.count}
                      product={product.product}
                      isSelected={product.isSelected}
                      changeSelected={changeSelected}
                      favorite={favorite}
                      changeCountProduct={changeCountProduct}
                      tryRemoveProduct={tryRemoveProduct}
                    />
                  ))
                : "корзина пуста"}
            </div>
            <div className={styles.order_block}>
              <div className={styles.order_block__order_price}>
                <span>Сумма заказа</span>
                <span>{orderPrice.orderPrice.toFixed(2)} ₽</span>
              </div>
              <div className={styles.order_block__price_of_goods}>
                <span>Стоимость товаров</span>
                <span>{orderPrice.priceOfGoods.toFixed(2)} ₽</span>
              </div>
              <div className={styles.order_block__discount}>
                <span>Cкидка</span>
                <span>
                  -
                  {(orderPrice.priceOfGoods - orderPrice.orderPrice).toFixed(2)}{" "}
                  ₽
                </span>
              </div>
              <div className={styles.order_block__button}>
                <Button
                  text="Оформить заказ"
                  maxWidth={isMobile ? "" : vw(484)}
                  width="100%"
                  padding={
                    isMobile ? `${vhM(10)} ${vwM(20)}` : `${vh(10)} ${vw(20)}`
                  }
                  bgColor="#20B900"
                  color="#ffffff"
                  fontWeight="600"
                  fontSize={isMobile ? vhM(20) : vh(20)}
                  lineHeight={isMobile ? vhM(40) : vh(36)}
                  click={openOrder}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.basket__empty}>
            <div className={styles.basket__header}>Корзина пуста</div>
            <div className={styles.basket__empty__additional_info}>
              Чтобы найти товары, воспользуйтесь поиском
            </div>
            <div className={styles.basket__empty__actions}>
              <Button
                text="Начать покупки"
                fontSize={isMobile ? vhM(16) : vh(16)}
                lineHeight={isMobile ? vhM(20) : vh(20)}
                fontWeight="600"
                padding={`${isMobile ? vhM(12) : vh(12)} ${
                  isMobile ? vwM(32) : vw(32)
                }`}
                bgColor="#20B900"
                borderRadius={isMobile ? vhM(10) : vh(10)}
                click={returnToMain}
              />
            </div>
            {hits ? (
              <div className={styles.basket__empty__hits}>
                <div className={styles.basket__empty__hits__header}>
                  Хиты продаж
                </div>
                <SliderProducts items={hits} />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        {idForDelete > -1 && (
          <ModalOneButton
            title="Удаление товара"
            description="Вы действительно хотите удалить товар? Отменить данное действие будет невозможно."
            buttonStyle={
              isMobile
                ? {
                    width: "100%",
                    padding: `${vhM(13.5)} ${vwM(27)}`,
                    fontSize: vhM(16),
                    lineHeight: vhM(21),
                  }
                : {
                    width: vw(312),
                    padding: `${vh(6)} ${vw(12)}`,
                    fontSize: vh(16),
                    lineHeight: vh(26),
                  }
            }
            button={{
              title: "Удалить",
              action: () => removeProduct(idForDelete),
            }}
            close={() => setIdForDelete(-1)}
          />
        )}
        {showOrderModal && (
          <ModalOneButton
            title="Оплата"
            description="Войдите в аккаунт, чтобы оплатить заказ."
            buttonStyle={
              isMobile
                ? {
                    width: "100%",
                    padding: `${vhM(13.5)} ${vwM(27)}`,
                    fontSize: vhM(16),
                    lineHeight: vhM(21),
                  }
                : {
                    width: vw(312),
                    padding: `${vh(6)} ${vw(12)}`,
                    fontSize: vh(16),
                    lineHeight: vh(26),
                  }
            }
            button={{
              title: "Войти",
              action: openAuth,
            }}
            close={() => setShowOrderModal(false)}
          />
        )}
        {messageInfo.length ? (
          <ModalOneButton
            title="Оплата"
            description={messageInfo}
            buttonStyle={
              isMobile
                ? {
                    width: "100%",
                    padding: `${vhM(13.5)} ${vwM(27)}`,
                    fontSize: vhM(16),
                    lineHeight: vhM(21),
                  }
                : {
                    width: vw(312),
                    padding: `${vh(6)} ${vw(12)}`,
                    fontSize: vh(16),
                    lineHeight: vh(26),
                  }
            }
            button={{
              title: "Закрыть",
              action: () => {
                setMessageInfo("");
              },
            }}
            close={() => setMessageInfo("")}
          />
        ) : (
          <></>
        )}
        {openOrderModal && (
          <ModalTwoButtons
            title={
              typeOfDelivery ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img src={pdp} alt={pdp} />
                  <h1 style={{ fontSize: "1.9vh", marginLeft: "1vh" }}>
                    Транспортная компания DPD
                  </h1>
                </div>
              ) : (
                "Оформление заказа"
              )
            }
            description={InputsForOrder}
            buttonStyle={
              isMobile
                ? {
                    width: "100%",
                    padding: `${vhM(13.5)} ${vwM(27)}`,
                    fontSize: vhM(16),
                    lineHeight: vhM(21),
                  }
                : {
                    width: "100%",
                    padding: `${vh(13.5)} ${vw(27)}`,
                    fontSize: vh(16),
                    lineHeight: vh(21),
                  }
            }
            button={{
              title: typeOfDelivery ? "Сохранить" : "Перейти к оплате",
              action: typeOfDelivery
                ? () => setTypeOfDelivery(false)
                : makeOrder,
            }}
            width={isMobile ? vwM(370) : vw(476)}
            close={
              typeOfDelivery
                ? () => setTypeOfDelivery(false)
                : () => setOpenOrderModal(false)
            }
            buttonClose={
              typeOfDelivery
                ? {
                    title: "Назад",
                  }
                : {
                    title: "Закрыть",
                  }
            }
          />
        )}

        {modalLink && (
          <ModalNoButton
            title=""
            description={
              <div style={{ fontSize: vh(18) }}>
                Пожалуйста, отключите блокировку всплывающих окон или кликните
                по ссылке:{" "}
                <Link path={payLink} color="blue">
                  Оплата
                </Link>
              </div>
            }
            close={() => setModalLink(false)}
          />
        )}
      </div>
    </>
  );
};

const ProductInBasket = ({
  id,
  inFavorite,
  product,
  count,
  isSelected,
  changeSelected,
  favorite,
  changeCountProduct,
  tryRemoveProduct,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <div className={styles.product}>
      <div className={styles.product__left_side}>
        <div className={styles.product__select}>
          <Checkbox
            isSelected={isSelected}
            width={isMobile ? vwM(20) : vw(20)}
            height={isMobile ? vwM(20) : vw(20)}
            change={(newValue) => changeSelected(id, newValue)}
          />
        </div>
        <div className={styles.product__img}>
          <Link path={`/product/${product.id}`}>
            <img
              src={
                product?.pic_urls?.length
                  ? product.pic_urls[0].pic_url
                  : emptyProduct
              }
              alt={product.name}
              width={isMobile ? vhM(130) : vh(130)}
              height={isMobile ? vhM(130) : vh(130)}
            />
          </Link>
        </div>
        <div className={styles.product__addition}>
          <div className={styles.product__addition__title}>
            <Link path={`/product/${product.id}`}>{product.name}</Link>
          </div>
          {!isMobile ? (
            <div className={styles.product__addition__actions}>
              <div
                className={styles.add_to_favorite}
                onClick={() => favorite(id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={vw(30)}
                  height={vw(30)}
                  viewBox="0 0 30 30"
                  fill={inFavorite ? "#DC7413" : "none"}
                >
                  <path
                    d="M14.513 8.29274L14.9286 9.07972L15.3442 8.29274C16.5517 6.00621 18.46 4.47 20.9888 4.47C24.6603 4.47 27.3871 7.53799 27.3871 11.3665C27.3871 13.8677 26.1982 15.9347 24.0395 18.2007C22.543 19.7716 20.6245 21.3954 18.3747 23.2997C17.3547 24.1631 16.2665 25.0841 15.1187 26.084C15.0099 26.1787 14.8471 26.1786 14.7384 26.084C13.5914 25.0848 12.5037 24.1642 11.484 23.3012C9.2355 21.398 7.31713 19.7744 5.82012 18.2031C3.66049 15.9363 2.47 13.8679 2.47 11.3665C2.47 7.53739 5.19724 4.47 8.8683 4.47C11.3971 4.47 13.3054 6.00607 14.513 8.29274Z"
                    stroke={inFavorite ? "#DC7413" : "#5F6B69"}
                    strokeWidth="0.94"
                  />
                </svg>
              </div>
              <div
                className={styles.remove}
                onClick={() => tryRemoveProduct(id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={vw(30)}
                  height={vw(30)}
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="nonzero">
                    <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                    <path
                      fill="#5F6B69"
                      stroke="#ffffff"
                      d="M14.28 2a2 2 0 0 1 1.897 1.368L16.72 5H20a1 1 0 1 1 0 2l-.003.071-.867 12.143A3 3 0 0 1 16.138 22H7.862a3 3 0 0 1-2.992-2.786L4.003 7.07A1.01 1.01 0 0 1 4 7a1 1 0 0 1 0-2h3.28l.543-1.632A2 2 0 0 1 9.721 2h4.558Zm3.717 5H6.003l.862 12.071a1 1 0 0 0 .997.929h8.276a1 1 0 0 0 .997-.929L17.997 7ZM10 10a1 1 0 0 1 .993.883L11 11v5a1 1 0 0 1-1.993.117L9 16v-5a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1Zm.28-6H9.72l-.333 1h5.226l-.334-1Z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          ) : (
            <CounterItem
              count={count}
              smallButton
              width="fit-content"
              boxSizing="border-box"
              padding={`${vhM(9)} ${vwM(28)}`}
              fontSize={vhM(20)}
              lineHeight={vhM(24)}
              color="#5F6B69"
              isNewStyle={true}
              change={(newValue) => changeCountProduct(id, newValue)}
            />
          )}
        </div>
      </div>
      {!isMobile && (
        <div className={styles.product__right_side}>
          <div className={styles.product__price}>
            {calcPrice(
              product.discount_type ? product.discount_price : product.price,
              count
            )}{" "}
            ₽
          </div>
          {product.discount_type && (
            <div className={styles.product__price_without_discount}>
              {calcPrice(product.price, count)} ₽
            </div>
          )}
          <div className={styles.product__change_count}>
            <CounterItem
              count={count}
              smallButton
              boxSizing="border-box"
              padding={`${vh(10)} ${vw(11.5)}`}
              change={(newValue) => changeCountProduct(id, newValue)}
            />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={styles.product__mobile__info}>
          <div className={styles.product__mobile__price}>
            {product.discount_type && (
              <div className={styles.product__price_without_discount}>
                {calcPrice(product.price, count)} ₽
              </div>
            )}
            <div className={styles.product__price}>
              {calcPrice(
                product.discount_type ? product.discount_price : product.price,
                count
              )}{" "}
              ₽
            </div>
          </div>
          <div className={styles.product__mobile__actions}>
            <div
              className={styles.add_to_favorite}
              onClick={() => favorite(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vwM(30)}
                height={vwM(30)}
                viewBox="0 0 30 30"
                fill={inFavorite ? "#DC7413" : "none"}
              >
                <path
                  d="M14.513 8.29274L14.9286 9.07972L15.3442 8.29274C16.5517 6.00621 18.46 4.47 20.9888 4.47C24.6603 4.47 27.3871 7.53799 27.3871 11.3665C27.3871 13.8677 26.1982 15.9347 24.0395 18.2007C22.543 19.7716 20.6245 21.3954 18.3747 23.2997C17.3547 24.1631 16.2665 25.0841 15.1187 26.084C15.0099 26.1787 14.8471 26.1786 14.7384 26.084C13.5914 25.0848 12.5037 24.1642 11.484 23.3012C9.2355 21.398 7.31713 19.7744 5.82012 18.2031C3.66049 15.9363 2.47 13.8679 2.47 11.3665C2.47 7.53739 5.19724 4.47 8.8683 4.47C11.3971 4.47 13.3054 6.00607 14.513 8.29274Z"
                  stroke={inFavorite ? "#DC7413" : "#5F6B69"}
                  strokeWidth="0.94"
                />
              </svg>
            </div>
            <div className={styles.remove} onClick={() => tryRemoveProduct(id)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vwM(30)}
                height={vwM(30)}
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="nonzero">
                  <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                  <path
                    fill="#5F6B69"
                    stroke="#ffffff"
                    d="M14.28 2a2 2 0 0 1 1.897 1.368L16.72 5H20a1 1 0 1 1 0 2l-.003.071-.867 12.143A3 3 0 0 1 16.138 22H7.862a3 3 0 0 1-2.992-2.786L4.003 7.07A1.01 1.01 0 0 1 4 7a1 1 0 0 1 0-2h3.28l.543-1.632A2 2 0 0 1 9.721 2h4.558Zm3.717 5H6.003l.862 12.071a1 1 0 0 0 .997.929h8.276a1 1 0 0 0 .997-.929L17.997 7ZM10 10a1 1 0 0 1 .993.883L11 11v5a1 1 0 0 1-1.993.117L9 16v-5a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1Zm.28-6H9.72l-.333 1h5.226l-.334-1Z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const calcPrice = (price = 0, count = 1) => {
  return Number(Number(count * Number(price).toFixed(2)).toFixed(2));
};
const calcOrderPrice = (newProducts) => {
  let newOrderPrice = {
    orderPrice: 0,
    priceOfGoods: 0,
  };
  for (let i = newProducts.length - 1; i >= 0; i--) {
    if (newProducts[i].isSelected) {
      newOrderPrice.orderPrice += calcPrice(
        newProducts[i]?.product?.discount_type
          ? newProducts[i]?.product?.discount_price || 0
          : newProducts[i]?.product?.price || 0,
        newProducts[i].count
      );
      newOrderPrice.priceOfGoods += calcPrice(
        newProducts[i].product.price,
        newProducts[i].count
      );
    }
  }
  return newOrderPrice;
};
const checkUnselected = (newProducts) => {
  return newProducts.length && !newProducts.some((e) => e.isSelected === false);
};

export { Basket };
