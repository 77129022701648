//Авторизация
export const SIGNUP = "/api/users/register";
export const LOGIN = "/api/users/login";
export const REFRESH_TOKEN = "/api/users/refresh";
export const SEND_EMAIL = "/api/users/restore/email/send";
export const VERIFY_CODE = "/api/users/restore/email/verify";
export const CHANGE_PASSWORD = "/api/users/restore/change_password";
export const ADD_ITEMS_FROM_UNAUTH = () => `/api/userproductinfo/anonymous/add`;

// Виды медицины
export const MEDICINE_FILTER = "/api/products/filter/symptoms?";
export const MEDICINE_PRODUCTS = "/api/products/filter/symptoms/result";

//Фильтры
export const SIMPLE_FILTER = "/api/products/filter/simple_filter";
export const FILTER = "/api/products/filter/params";
export const COMPLEX_FILTER = "/api/products/filter/complex_filter";

//Корзина
export const GET_CART = () => `/api/userproductinfo/cart/my_cart`;
export const ADD_TO_CART = (id) =>
  `/api/userproductinfo/cart/${id}/add_product`;
export const REMOVE_FROM_CART = (id) =>
  `/api/userproductinfo/cart/${id}/remove_product`;
export const CHANGE_CART_PRODUCT_COUNT = (id) =>
  `/api/userproductinfo/cart/${id}/product_count`;
export const GET_COUNT_IN_CART = () => `/api/userproductinfo/cart/cart_count`;
export const GET_CART_FOR_UNAUTH = () => `/api/userproductinfo/anonymous/cart`;
export const GET_SUM = "/api/payments/delivery_price";
export const GET_TERMINALS = (city, address) =>
  `/api/info/terminal?city=${city}&address=${address}`;

//Избранное
export const GET_FAVORITE = (order_by) =>
  `/api/userproductinfo/favorite/my_favorites?order_by=${order_by}`;
export const ADD_TO_FAVORITE = (id) =>
  `/api/userproductinfo/favorite/${id}/add_product`;
export const REMOVE_FROM_FAVORITE = (id) =>
  `/api/userproductinfo/favorite/${id}/remove_product`;
export const ADD_TO_FAVORITE_SERVICE = (id) =>
  `/api/userproductinfo/favorite/${id}/add_service`;
export const REMOVE_FROM_FAVORITE_SERVICE = (id) =>
  `/api/userproductinfo/favorite/${id}/remove_service`;
export const CHANGE_FAVORITE_PRODUCT_COUNT = (id) =>
  `/api/userproductinfo/favorite/${id}/product_count`;
export const GET_FAVORITE_FOR_UNAUTH = () =>
  `/api/userproductinfo/anonymous/favorite`;

//Статические страницы
export const STATIC_INFO = (typeOfInfo) =>
  `/api/info/static_info/${typeOfInfo}`;
export const SOCIAL_LINK = (SocialNetworkType) =>
  `/api/info/link/${SocialNetworkType}`;
export const DOWNLOAD_REQUISITES = `/api/info/file/Реквизиты.pdf`;
export const DOWNLOAD_ALL_DOCS = `/api/users/profile/all_docs`;
export const GET_ALL_DOCS = `/api/info/file/all_docs`;
export const GET_DOC = (name) => `/api/info/file/${name}`;

//Чат
export const GET_CHAT = (page) => `/api/users/chat/questions?page=${page}`;
export const POST_QUESTION = "/api/users/chat/create";
export const PUT_QUESTION_SEEN = "/api/users/chat/check_seen";
export const GET_QUESTION_NEW = "/api/users/chat/has_new";

// Главная
export const GET_MAIN_TYPES = "/api/products/main-page/main_types";
export const GET_HITS = "/api/products/main-page/hits";
export const GET_PROMOTIONS = "/api/products/main-page/promotions";
export const GET_NEW = "/api/products/main-page/new";
export const GET_SERVICES = "/api/products/main-page/services";

// Каталог
export const GET_SERVISE_TYPES = "/api/products/catalog/service_types";
export const GET_SERVISE = (type) =>
  `/api/products/catalog/services?service_types=${type}`;
export const GET_PRODUCT = (id) => `/api/products/product/${id}/info`;
export const GET_PRODUCT_REVIEWS = (id) =>
  `/api/products/product/${id}/reviews`;
export const GET_SIMILAR = (id) => `/api/products/product/${id}/similar`;
export const GET_SERVICE = (id) => `/api/products/service/${id}/info`;

//Профиль
export const GET_PROFILE = () => `/api/users/profile/info`;
export const GET_PROFILE_DETAILS = "/api/users/profile/details";
export const UPDATE_BASIC_PROFILE = "/api/users/profile/details/basic/change";
export const UPDATE_ADD_PROFILE =
  "/api/users/profile/details/additional/change";
export const UPDATE_PIC_PROFILE = "/api/users/profile/details/avatar/change";
export const DELETE_PROFILE = "/api/users/profile/delete";
export const GET_TRACK_ORDERS = "/api/users/profile/deliveries";
export const GET_USER_HISTORY = "/api/users/profile/history";
export const GET_PURCHASE = "/api/users/profile/purchases";
export const GET_PURCHASE_PRODUCT = (id) =>
  `/api/users/profile/purchase/${id}/product`;
export const SEND_MARK = (id) => `/api/users/profile/purchase/${id}/create`;
export const GET_CHAT_PURCHASE = (id) =>
  `/api/users/profile/purchase/${id}/chat`;
export const SEND_QUESTION = (id) =>
  `/api/users/profile/purchase/${id}/message`;
export const GET_MY_REVIEWES = "/api/users/profile/reviews";
export const EDIT_REVIEW = (id) => `/api/users/profile/review/${id}/`;
export const GET_CHAT_LIST_PROFILE = (query = "", page = 1) =>
  `/api/users/profile/chat/user_list?page=${page}&query=${query}`;
export const GET_CHAT_ORDER_PROFILE = (order = "", page = 1) =>
  `/api/users/profile/chat/messages/${order}?page=${page}`;
export const SEND_MESSAGE_CHAT_PROFILE = (order = "") =>
  `/api/users/profile/chat/send/${order}`;
export const CREATE_CANCEL_ORDER = "/api/users/profile/deliveries/order_cancel";
export const CREATE_RETURN_PRODUCT =
  "/api/users/profile/purchase/product_return";

//Продавец
export const SELLER_CHECK = "/api/users/seller/seller_check";
export const BECOME_SELLER = "/api/users/seller/become_seller";
export const BECOME_SELLER_INFO = "/api/users/seller/become_seller/info";
export const AGREEMENT = "/api/users/seller/agreement";
export const USER_CONDITIONS = "/api/info/file/Условия использования сайта.pdf";
export const PRIVACY_POLICY =
  "/api/info/file/Политика обработки персональный данных.pdf";
export const GET_NOTIFICATIONS =
  "/api/users/profile/order_status/notifications";
export const CHANGE_NOTIFICATION_SEEN = (id) =>
  `/api/users/profile/order_status/notification/${id}/seen`;
export const GET_STATUS_HISTORY = (id) =>
  `/api/users/profile/order_status/notifications/${id}`;
export const SEND_TRACK_CODE = (id) =>
  `/api/userproductinfo/purchases/seller/${id}/link`;
export const SELLER = "/api/users/seller/info";
export const SELLER_DETAILS = "/api/users/seller/details";
export const SELLER_CONTACT_DETAILS =
  "/api/users/seller/details/contact/change";
export const SELLER_ADD_DETAILS = "/api/users/seller/details/additional/change";
export const SELLER_RESPONSIBLE_DETAILS =
  "/api/users/seller/details/responsible/change";
export const SELLER_BANK_DETAILS = "/api/users/seller/details/bank/change";
export const SELLER_AVATAR_DETAILS = "/api/users/seller/details/avatar/change";
export const SELLER_DPD_DETAILS = "/api/users/seller/details/dpd/change";
export const SELLER_DELETE = "/api/users/seller/delete";
export const GET_SELLER_NOTIFICATIONS_COUNT = () =>
  `/api/users/seller/notifications/count`;
export const GET_SELLER_NOTIFICATIONS = (page = 1) =>
  `/api/users/seller/notifications/list?page=${page}`;
export const SET_NOTIFICATION_IS_SEEN = (id) =>
  `/api/users/seller/notification/check/${id}`;
export const GET_SELLER_TERMINALS = (address) =>
  `/api/info/terminal?point_type=pickup&address=${address}`;

//Поставки
export const ORDERS_HANDLER = "/api/users/seller/orders?";
export const CHANGE_ORDER_STATUS = (id) =>
  `/api/users/seller/order/${id}/status`;
export const CREATE_ORDER = (order_number) =>
  `/api/users/seller/order/${order_number}/create_delivery`;
export const CANCEL_ORDER = (order_number) =>
  `/api/users/seller/order/${order_number}/cancel`;
export const GET_ORDER_DETAILS = (id) =>
  `/api/users/seller/order/${id}/details`;
export const CHANGE_NOTIFY_STATUS = (id) =>
  `/api/users/seller/order/${id}/notification`;
export const GET_WAREHOUSES = "/api/users/seller/warehouses?";
export const ADD_WAREHOUSE = "/api/users/seller/warehouse/create";
export const UPDATE_WAREHOUSE = (id) =>
  `/api/users/seller/warehouse/${id}/update`;
export const DELETE_WAREHOUSE = (id) =>
  `/api/users/seller/warehouse/${id}/delete`;
export const GET_EXEL_EXPORT = "/api/users/seller/warehouses/excel";
export const GET_CANCELED_ORDERS = "/api/users/seller/product_returns?";
export const GET_ORDER_DOCUMENTS = (order_number) =>
  `/api/users/seller/order/${order_number}/docs`;
export const ADD_ORDER_DOCUMENT = (order_number) =>
  `/api/users/seller/order/${order_number}/docs/add`;
export const REMOVE_ORDER_DOCUMENT = (id) =>
  `/api/users/seller/order/docs/remove/${id}`;
export const SEND_MESSAGE_CANCELED_ORDER = (pk) =>
  `/api/users/seller/product_return/${pk}/message`;
export const GET_TRACKING_ORDERS = "/api/users/seller/order_tracking?";
export const GET_FILTER_PARAMS = "/api/users/seller/product_returns/params";
export const DELETE_CANCELED_ORDER = (pk) =>
  `/api/users/seller/product_return/${pk}/cancel`;
export const APPROVE_RETURN_ORDER = (pk) =>
  `/api/users/seller/product_return/${pk}/approve`;

//Акции и скидки
export const PROMOTIONS_DISCOUNT = "/api/users/seller/promotions?";
export const PROMOTIONS_DISCOUNT_INFO = (id) =>
  `/api/users/seller/promotion/${id}/details`;
export const PRODUCTS_PROMOTIONS_DISCOUNT = (id) =>
  `/api/users/seller/promotion/${id}/products`;
export const ADD_PRODUCTS_PROMOTIONS_DISCOUNT = (id) =>
  `/api/users/seller/promotion/${id}/products/add`;
export const DELETE_PRODUCTS_PROMOTIONS_DISCOUNT = (id) =>
  `/api/users/seller/promotion/${id}/products/delete`;
export const CREATE_PROMOTION = () => `/api/users/seller/promotion/create`;
export const UPDATE_PROMOTION = (id) =>
  `/api/users/seller/promotion/${id}/update`;
export const DELETE_PROMOTION = (id) =>
  `/api/users/seller/promotion/${id}/delete`;
export const GET_PRODUCTS_FOR_CREATE_PROMOTION = () =>
  `/api/users/seller/promotion/form/products`;
export const GET_RAW_PROMOTION = (id) =>
  `/api/users/seller/promotion/${id}/raw`;

// Аналитика
export const FUNNEL_ANALYTICS = (period) =>
  `/api/users/seller/analytics/funnel?period=${period}`;
export const FUNNEL_EXCEL_ANALYTICS = (period) =>
  `/api/users/seller/analytics/funnel/excel?period=${period}`;
export const PRODUCT_ANALYTICS = (id, period) =>
  `/api/users/seller/analytics/product/${id}?period=${period}`;
export const PRODUCT_EXCEL_ANALYTICS = (id, period) =>
  `/api/users/seller/analytics/product/${id}/excel?period=${period}`;
export const CLIENT_ANALYTICS = (period) =>
  `/api/users/seller/analytics/clients?period=${period}`;
export const CLIENT_EXCEL_ANALYTICS = (period) =>
  `/api/users/seller/analytics/clients/excel?period=${period}`;
export const MAIN_ANALYTICS = (period) =>
  `/api/users/seller/analytics/main?period=${period}`;
export const FINANCIAL_EXCEL = (period) =>
  `/api/users/seller/analytics/financial/excel?period=${period}`;
export const GET_TREND_ANALYTICS = (period, type) =>
  `/api/users/seller/analytics/trend?period=${period}&type=${type}`;
export const GET_RELATIONSHIPS_ANALYTICS =
  "/api/users/seller/analytics/relationships";
export const GET_SEASONALITY_ANALYTICS =
  "/api/users/seller/analytics/seasonality";
export const GET_SERVICE_ANALYTICS = (id, params = "") =>
  `/api/users/seller/analytics/service/${id}?${params}`;
export const GET_SERVICE_ANALYTICS_EXCEL = (id, params = "") =>
  `/api/users/seller/analytics/service/${id}/excel?${params}`;

//Создание продукта и услуги
export const GET_CITIES = (name = "") => `/api/info/city?name=${name}`;
export const MEDICINE_CREATE_PRODUCT = "/api/products/product/basic";
export const CATEGORIES_CREATE_PRODUCT = (id) =>
  `/api/products/product/specific/${id}`;
export const CREATE_PRODUCT = "/api/products/product/create";
export const GET_RAW_PRODUCT = (id) => `/api/products/product/raw/${id}`;
export const GET_RAW_SERVICE = (id) => `/api/products/service/raw/${id}`;
export const UPDATE_PRODUCT = (id) => `/api/products/product/update/${id}`;
export const UPDATE_SERVICE = (id) => `/api/products/service/update/${id}`;

export const MEDICINE_CREATE_SERVICE = "/api/products/service/basic";
export const CATEGORIES_CREATE_SERVICE = (id) =>
  `/api/products/service/specific/${id}`;
export const CREATE_SERVICE = "/api/products/service/create";
export const SELLER_PRODUCTS = "/api/users/seller/products";
export const WATCH_REMOTE_SERVICE_URL = (id) =>
  `/api/products/service/remote_url/${id}?`;

//Оформление заказа
export const GET_PAYMENT_URL = () => `/api/payments/payment_url`;

//анкета
export const GET_ILLNESSES = () => `/api/products/questionnaire/illnesses`;
export const GET_SYMPTOMS = () => `/api/products/questionnaire/symptoms`;
export const GET_ILLNESSES_RESULT = () =>
  `/api/products/questionnaire/illnesses/catalog`;
export const GET_SYMPTOMS_RESULT = () =>
  `/api/products/questionnaire/symptoms/catalog`;
export const GET_AGES = () =>
  `/api/products/questionnaire/year_classifications`;
export const GET_AGES_RESULT = () =>
  `/api/products/questionnaire/year_classifications/catalog`;

//Вики
export const GET_ARTICLES = (page = 1, params = "") =>
  `/api/article/list?page=${page}&${params}`;
export const GET_ARTICLE = (id) => `/api/article/${id}/read`;
export const FAVORITE_ARTICLE = (id) => `/api/article/${id}/favorite`;
export const CREATE_ARTICLE = () => `/api/article/create`;
export const GET_ARTICLE_SYMPTOMS = () => `/api/article/create/params`;
export const GET_MY_ARTICLES = (type, page = 1, params = "") =>
  `/api/article/list/my?type=${type}&page=${page}&${params}`;
export const GET_MY_ARTICLE = (id) => `/api/article/${id}/raw`;
export const UPDATE_MY_ARTICLE = (id) => `/api/article/${id}/update`;
export const REMOVE_MY_ARTICLE = (id) => `/api/article/${id}/delete`;
export const GET_NEWS_LIST = (page = 1, params = "") =>
  `/api/news/list?page=${page}&${params}`;
export const GET_NEWS = (id) => `/api/news/${id}/read`;

export const GET_FORUM_LIST = (page = 1, params = "") =>
  `/api/forum/list?page=${page}&${params}`;
export const GET_MY_FORUM_LIST = (page = 1, params = "") =>
  `/api/forum/list/my?page=${page}&${params}`;
export const GET_FORUM_DETAILS = (id) => `/api/forum/${id}`;
export const GET_FORUM_COMMENTS = (id, page = 1) =>
  `/api/forum/${id}/comments?page=${page}`;
export const CREATE_FORUM_THEME = () => `/api/forum/create`;
export const GET_RAW_DATA_THEME = (id) => `/api/forum/${id}/raw`;
export const UPDATE_THEME = (id) => `/api/forum/${id}/update`;
export const CLOSE_THEME = (id) => `/api/forum/${id}/close`;
export const CREATE_COMMENT = (id) => `/api/forum/${id}/comments/create`;

//Чат
export const GET_CHAT_LIST = (query = "", page = 1) =>
  `/api/users/seller/chat/user_list?page=${page}&query=${query}`;
export const GET_CHAT_ORDER = (order = "", page = 1) =>
  `/api/users/seller/chat/messages/${order}?page=${page}`;
export const SEND_MESSAGE_CHAT = (order = "") =>
  `/api/users/seller/chat/send/${order}`;

//Мой магазин
export const GET_MY_GOODS = (params) => `/api/users/seller/my_goods?${params}`;
export const GET_MY_GOODS_FILTER = () => `/api/users/seller/my_goods/filter`;
export const DELETE_MY_GOOD = (id) => `/api/users/seller/my_good/${id}/delete`;
export const DELETE_MY_GOOD_SERVICE = (id) =>
  `/api/users/seller/my_good/${id}/service_delete`;
export const CHANGE_STATUS_MY_GOOD = (id) =>
  `/api/users/seller/my_good/${id}/status`;
export const CHANGE_STATUS_MY_GOOD_SERVICE = (id) =>
  `/api/users/seller/my_good/${id}/service_status`;
export const GET_SYMPTOMS_MY_GOOD = () => `/api/users/seller/my_goods/symptoms`;
export const SET_TAGS_MY_GOOD = (id) => `/api/users/seller/my_good/${id}/tags`;
export const GET_EXCEL_MY_GOODS = (products) =>
  `/api/users/seller/my_goods/excel?products=${products}`;
export const GET_PINNED_REVIEWS = (page = 1) =>
  `/api/users/seller/reviews/pinned?page=${page}`;
export const GET_REVIEW_LIST = (page = 1, params = "") =>
  `/api/users/seller/reviews/list?page=${page}&${params}`;
export const GET_PRODUCT_FOR_REVIEWS = (id, type = "archive") =>
  `/api/users/seller/reviews/product/${id}/details?type=${type}`;
export const GET_REVIEWS_FOR_PRODUCT = (id, type = "archive") =>
  `/api/users/seller/reviews/product/${id}/reviews?type=${type}`;
export const PIN_REVIEW = (id) => `/api/users/seller/review/${id}/pin`;
export const ANSWER_REVIEW = (id) => `/api/users/seller/review/${id}/comment`;
