import React, { useState, useEffect } from "react";
import styles from "./News.module.scss";
import { axiosNews } from "plugins/axios";
import { MainMenu, ModalImg } from "components";
import { useParams } from "react-router-dom";
import { checkError } from "plugins/checkError";
import parse from "html-react-parser";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { useMediaQuery } from "react-responsive";

const News = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { id: idNews } = useParams();
  const [news, setNews] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!isHovered && !modalOpen) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % news?.cover_urls?.length
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isHovered, modalOpen, news?.cover_urls?.length]);

  useEffect(() => {
    const getNews = async () => {
      let resNews = await axiosNews.getNews(idNews);
      let { isError, messageError } = checkError(resNews);
      if (isError) {
        return;
      }
      let date = resNews?.creation_date?.split("T")[0].split("-");
      setNews({
        ...resNews,
        creation_date: `${date[2]}.${date[1]}.${date[0]}`,
      });
    };
    getNews();
    window.scrollTo(0, 0);
  }, []);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleImageClick = () => {
    setModalOpen(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {modalOpen ? (
        <ModalImg
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          pic_urls={news?.cover_urls || []}
          main_img={currentIndex}
        />
      ) : null}
      {!isMobile && (
        <div className={styles.menu}>
          <MainMenu
            visibleNews={true}
            visibleServices={false}
            visibleForum={true}
            titleMedicines="Познай свой мир медицины"
            isArticles={true}
            selectNews={true}
          />
        </div>
      )}
      <div className={styles.news__cont_2}>
        <div className={styles.news__name}>{news?.name}</div>
        <div className={styles.news__date}>{news?.creation_date}</div>

        {news?.cover_urls?.length ? (
          <div className={styles.slider_news}>
            <div
              className={styles.slider_news__image}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={handleImageClick}
            >
              <img
                src={news?.cover_urls[currentIndex].pic_url}
                alt={`slide-${currentIndex}`}
              />
            </div>
            {news?.cover_urls?.length > 1 && (
              <div className={styles.slider_news__dots}>
                {news?.cover_urls?.map((_, index) => (
                  <span
                    key={index}
                    className={`${styles.dot} ${
                      currentIndex === index ? styles.active : ""
                    }`}
                    onClick={() => handleDotClick(index)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className={styles.news__parse}>
          {parse(replaceStyles(news?.full_text || ""))}
        </div>
      </div>
    </div>
  );
};

export { News };

const replaceStyles = (input, isMobile = false) => {
  const replacePaddingLeft = (match, p1) => {
    return `padding-left: ${isMobile ? vwM(p1) : vw(p1)}`;
  };
  const replaceFontSize = (match, p1) => {
    return `font-size: ${isMobile ? vhM(p1) : vh(p1)}`;
  };
  const paddingLeftPattern = /padding-left:\s*(\d+)px/g;
  let result = input.replace(paddingLeftPattern, replacePaddingLeft);
  const fontSizePattern = /font-size:\s*(\d+)px/g;
  result = result.replace(fontSizePattern, replaceFontSize);

  return result;
};
